$url: '../image/';
$fonts-size: 15px;
$font: 'Roboto', sans-serif;
$font-header: 'Yeseva One', cursive;
body {

  /* contact page */
  .form-contact {
    legend {
      font-size: 18px;
      border-bottom: none;
    }
    .buttons {
      margin-top: 15px;
    }
  }
  label.control-label {
    font-size: 13px;
    font-weight: 400;
    &.label-textarea {
      padding-top: 50px;
      @media (max-width: 767px) {
        padding-top: 7px;
      }
    }
  }
  .contact-info {
    margin-bottom: 25px;
    span {
      font-weight: 500;
      margin-bottom: 5px;
      display: inline-block;
    }
  }
  .map {
    margin-top: 100px;
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  /* contact page */

  /* account page */

   .subtitle {
      padding-bottom: 10px;
      font-size: 18px;
      margin-bottom: 25px;
      margin-top: 0;
    }
    .account-list {
      li {
        margin-bottom: 10px;
      }
    }
    .buttons {
      margin-top: 60px;
      @media (max-width: 767px) {
        margin-top: 30px;
      }
    }
    fieldset + .buttons {
      margin-top: 30px;
    }
  .well-border {
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 30px;
    margin-bottom: 20px;
    a{
      font-size: 13px;
      text-decoration: none;
      font-weight: 500;
    }
    label.control-label {
      font-size: 15px;
    }
    h2 + p{
      strong{
        font-weight: 500;
      }
    }
    .btn-submit {
      font-size: 15px;
      font-weight: bold;
    }
  }
  .col-3{
    .col-login{
      width: 100%;
    }
    .form-width{
      label{
        width: 100%;
        text-align: left;
        padding-bottom: 5px;
        & + div[class*='col-']{
          width: 100%;
        }
      }
      .radio-label{
        label {
          width: auto;
        }
      }
    }
    label.control-label {
      &.label-textarea {
        padding-top: 7px;
      }
    }
  }
  .col-2 {
    @media (min-width: 768px) and (max-width: 992px) {
      .col-login {
        width: 100%;
      }
      .form-width{
        label{
          width: 100%;
          text-align: left;
          padding-bottom: 5px;
          & + div [class*='col-']{
            width: 100%;
          }
        }
        .radio-label{
          label {
            width: auto;
          }
        }
      }
      label.control-label {
        &.label-textarea {
          padding-top: 7px;
        }
      }
    }
  }
  fieldset{
    margin-bottom: 40px;
  }
  .btn-calendar {
    border: none;
    padding: 5px;
    width: 30px;
    .fa {
      font-size: 18px;
    }
  }
  .input-group-btn {
    &:last-child > .btn-calendar {
      margin-left: 15px;
    }
  }
  .form-horizontal{
    .radio-label{
      .radio-inline{
        padding-top: 0;
      }
    }
  }
  .form-label{
    margin-top: 40px;
  }
  .table-border{
    margin-bottom: 50px;
    thead, tbody{
      &:first-child>tr:first-child>td{
        padding: 10px 15px;
      }
      &>tr>td{
        padding: 15px;
        vertical-align: middle;
        min-width: 115px;
        &.td-remove{
          width: 50px;
          min-width: 50px;
        }
      }
    }
    .price, .total{
      font-size: 18px;
      font-family: $font-header;
      margin-bottom: 0;
    }
    .stock{
      font-size: 13px;
    }
  }
  .table-no-border{
    &>tbody>tr>td{
      border-top: none;
      &.td-remove{
        width: 50px;
        min-width: 50px;
      }
    }
  }
  .table-wishlist{
    margin-bottom: 15px;
  }
  /* account page */

  /* address page */
  .address-edit {
    padding-top: 7px;
  }
  .address-table {
    margin: 0;
    .text-left{
      padding: 20px;
    }
    .text-right {
      width: 65px;
      vertical-align: middle;
      text-align: center;
      a {
        display: block;
        font-size: 17px;
        padding: 8px;

      }
    }
  }
  /* address page */

  /* cart page */
  .product-quant{
    font-family: $font;
    font-size: 13px;
    padding-left: 15px;
    font-weight: 400;
    @media (max-width: 767px) {
      display: block;
      padding-left: 0;
    }
  }
  .img-thumb{
    border: 0;
    border-radius: 0;
  }
  .quantity-contain{
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    input{
      border: none;
      text-align: center;
      height: 30px;
      &[disabled]{
        width: 28px;
        margin: 0 20px;
      }
    }
    span{
      height: 30px;
      width: 15px;
      border-radius: 3px;
      display: inline-block;
      font-size: 10px;
      padding: 8px 3px;
      &:hover{
        cursor: pointer;
      }
    }
  }
  .module-cart{
    display: block;
    .input-group-btn{
      position: absolute;
      right: 0;
      top: 0;
      display: block;
      width: auto;
      opacity: 0;
      z-index: 2;
      -webkit-transition: .3s;
      -moz-transition: .3s;
      -ms-transition: .3s;
      -o-transition: .3s;
      transition: .3s;
      .btn{
        border-radius: 0;
        font-size: 13px;
        font-weight: normal;
        letter-spacing: 0;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
    &.input-group .form-control{
      margin-bottom: 15px;
      float: none;
      &::placeholder{
        text-transform: uppercase;
      }
      &:-moz-placeholder{
        text-transform: uppercase;
      }
      &:-ms-input-placeholder{
        text-transform: uppercase;
      }
      &::-moz-placeholder{
        text-transform: uppercase;
      }
      &::-webkit-input-placeholder{
        text-transform: uppercase;
      }
      &:focus{
        font-size: 13px;
        padding-right: 100px;
        &::placeholder{
          font-size: 13px;
          text-transform: none;
        }
        &:-moz-placeholder{
          font-size: 13px;
          text-transform: none;
        }
        &:-ms-input-placeholder{
          font-size: 13px;
          text-transform: none;
        }
        &::-moz-placeholder{
          font-size: 13px;
          text-transform: none;
        }
        &::-webkit-input-placeholder{
          font-size: 13px;
          text-transform: none;
        }
        & + .input-group-btn{
          opacity: 1;
        }
      }
    }
  }
  .table-total{
    margin-top: 10px;
    margin-bottom: 0;
    &>tbody>tr{
      &:last-child{
        &>td{
          &:first-child{
            font-size: 18px;
            font-family: $font;
          }
          &:last-child{
            font-size: 22px;
            font-family: $font-header;
            width: 130px;
            @media (max-width: 767px){
              width: 100%;
            }
          }
        }
      }
      &>td{
        @media (max-width: 767px) {
          display: block;
          text-align: left;
        }
        &:first-child{
          font-weight: 500;
        }
      }
    }
  }
  .button-col{
    @media (max-width: 767px) {
      width: 100%;
      float: none;
      text-align: center;
      margin-bottom: 10px;
    }
  }
  .panel-group .panel-shipping{
    margin-bottom: 10px;
  }
  /* cart page */

  .heading-map{
    text-transform: uppercase;
    font-size: 15px;
    margin-bottom: 15px;
    display: inline-block;
  }
  .page-map{
    ul{
      padding-left: 0;
      list-style-type: none;
      li{
        a{
          text-decoration: none;
          line-height: 35px;
          &:hover{
            text-decoration: underline;
          }
        }
        ul{
          padding-left: 20px;
          &.list-map{
            padding-left: 0;
          }
        }
      }
    }
  }
  .col-3{
    .col-map{
      min-width: 50%;
    }
  }
  .not-found{
    font-family: $font-header;
    font-size: 70px;
    @media (max-width: 767px) {
      font-size: 50px;
    }
    span{
      font-size: 140px;
      display: block;
      line-height: 110px;
      margin-bottom: 20px;
      @media (max-width: 767px) {
        font-size: 90px;
      }
    }
  }
  .information{
    p{
      margin-bottom: 25px;
    }
  }

  /* Category Page */
  .heading-sm{
    font-size: 18px;
  }
  .description-category-container{
      margin-bottom: 30px;
      &:after{
      content: '';
      display: table;
      clear: both;
    }
    .category-img {
      margin-right: 30px;
      margin-bottom: 10px;
      float: left;
      img {
        max-width: 262px;
      }
    }
    @media (max-width: 480px) {
    .description-category {
      clear: left;
    }
  }
  }
  .sub_category{
    margin-top: 15px;
    margin-bottom: 35px;
    display: flex;
    flex-wrap: wrap;
    li{
      -webkit-transition: .3s;
      -moz-transition: .3s;
      -ms-transition: .3s;
      -o-transition: .3s;
      transition: .3s;
      padding: 10px;
      border-radius: 3px;
      flex: 0 1 142px;
      margin: 15px 15px;
      @media (max-width:767px) {
        flex: 0 1 130px;
      }
    }
    .sub_category_name{
      display: block;
      padding: 15px 5px 5px 5px;
      word-break: break-word;
      font-size: 14px;
      span{
        font-size: 13px;
      }
    }
    a{
      text-decoration: none;
    }
    img{
      margin: 0 auto;
      display: block;
    }
  }
  .top-sort-panel{
    padding-top: 15px;
    margin-top: 25px;
    .input-group-addon{
      background: transparent;
      border: none;
      font-size: 15px;
      width: auto;
      padding-right: 15px;
    }
    &:after{
      content: '';
      display: table;
      clear: both;
    }
    select{
      min-width: 67px;
      max-width: 165px;
      border-radius: 3px !important;
    }
    .btn-view{
      padding-right: 20px;
      .btn{
        font-size: 16px;
      }
    }
    .pagination{
      margin: 0 0 10px 0;
    }
    &.bottom{
      margin-top: 45px;
    }
  }
  .product-grid{
    margin-top: 30px;
    margin-bottom: 30px;
    .product-thumb_descript{
      display: none;
    }
    .btn-list{
      display: none;
    }
    .button-group{
      margin-top: 10px;
    }
  }
  .product-list{
    margin-top: 30px;
    .product-thumb{
      display: flex;
      flex-wrap: wrap;
      .image {
        margin-right: 30px;
        flex: 0 0 260px;
      }
      .caption{
        text-align: left;
        flex: 1 0 300px;
        &:after {
          content: '';
          display: table;
          clear: both;
        }
        .rating {
          float: left;
          margin-right: 25px;
          padding-top: 5px;
        }
      }
      .product-thumb_manufacturer{
        display: none;
      }
      .product-thumb_descript{
        font-weight: 400;
      }
      .price{
        float: left;
        margin-bottom: 40px;
        .price-new{
          margin-right: 25px;
        }
      }
      .price + .button-group{
        float: left;
      }
      .btn-absolute{
        .wishlist, .compare{
          display: none;
        }
      }
      .btn-list{
        clear: both;
        .btn-round{
          width: auto;
          background: transparent;
          font-size: 13px;
          font-weight: 400;
          letter-spacing: 0;
          border-radius: 0;
          .fa{
            padding-right: 10px;
            font-size: 15px;
          }
          &:first-child{
            padding-right: 12px;
            margin-right: 5px;
            position: relative;
            &:after{
              content: '|';
              position: absolute;
              right: 0;
            }
          }
        }
      }
      &:after {
        content: '';
        display: table;
        clear: both;
      }
    }
  }
  .product-thumb{
    &:hover{
      .btn-absolute{
        opacity: 1;
        @media (max-width:767px) {
          display: none;
        }
      }
      .image{
        -webkit-transition: .5s;
        -moz-transition: .5s;
        -ms-transition: .5s;
        -o-transition: .5s;
        transition: .5s;
      }
    }
    .image{
      -webkit-transition: .5s;
      -moz-transition: .5s;
      -ms-transition: .5s;
      -o-transition: .5s;
      transition: .5s;
      padding: 10px;
      text-align: center;
      position: relative;
      border-radius: 3px;
      min-height: 100px;
      &>a{
        display: block;
        width: 100%;
      }
    }
    .caption{
      font-weight: 500;
      margin-bottom: 0;
      a{
        text-decoration: none;
      }
      .head-product{
        display: inline-block;
        margin-top: 15px;
        min-height: 43px;
        @media (max-width:767px) {
          min-height: 15px;
        }
          a{
            &:hover{
            text-decoration: underline;
            }
          }
      }
      .product-thumb_manufacturer{
        font-size: 12px;
        font-weight: 400;
        text-transform: uppercase;
      }
    }
    .btn-absolute{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      margin-bottom: 15px;
      opacity: 0;
      -webkit-transition: .5s;
      -moz-transition: .5s;
      -ms-transition: .5s;
      -o-transition: .5s;
      transition: .5s;
    }
  }
  .price{
    font-family: $font-header;
    margin-bottom: 0;
    .price-new{
      font-size: 26px;
      span{
        font-size: 16px;
      }
    }
    .price-old{
      font-size: 22px;
      text-decoration: line-through;
      padding-right: 10px;
      span{
        display: none;
      }
    }
    &>span{
      display: inline-block;
    }
  }
  .show-more{
    text-transform: uppercase;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .product-thumb{
    .btn-round.btn-view{
      .fa{
        line-height: 26px;
      }
    }
  }
  .products-category{
    .product-thumb{
      .image{
        max-width: 260px;
        margin: 0 auto;
      }
    }
    .product-list {
      .product-thumb {
        .image {
          margin-right: 30px;
          @media (min-width: 768px) and (max-width: 991px){
            margin-right: auto;
          }
        }
      }
    }
  }
  @media (min-width: 1200px) {
    #content .col-lg-2:nth-child(6n+1),
    #content .col-lg-2:nth-child(6n+1),
    #content .col-lg-3:nth-child(4n+1),
    #content .col-lg-4:nth-child(3n+1),
    #content .col-lg-6:nth-child(2n+1) {
      clear:left;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    #content .col-md-2:nth-child(6n+1),
    #content .col-md-2:nth-child(6n+1),
    #content .col-md-3:nth-child(4n+1),
    #content .col-md-4:nth-child(3n+1),
    #content .col-md-6:nth-child(2n+1) {
      clear:left;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    #content .col-sm-2:nth-child(6n+1),
    #content .col-sm-2:nth-child(6n+1),
    #content .col-sm-3:nth-child(4n+1),
    #content .col-sm-4:nth-child(3n+1),
    #content .col-sm-6:nth-child(2n+1) {
      clear:left;
    }
  }
  .pagination{
    width: 100%;
    &>li{
      &>a, &>span{
        border: none;
        margin: 0 4px;
        border-radius: 3px;
        font-size: 13px;
        padding-left: 11px;
        padding-right: 11px;
      }
    }
  }
  .col-3{
    .top-sort-panel{
      .input-group-addon{
        display: none;
      }
    }
  }
  /* Category Page */

  /* Search Page */
  &.product-search{
    h2.heading{
      text-align: left;
      padding-bottom: 0;
    }
    .checkbox-inline{
      font-size: 13px;
    }
    .buttons{
      margin-top: 30px;
      margin-bottom: 60px;
    }
    @media (max-width: 767px) {
      input.form-control{
        margin-bottom: 10px;
      }
    }
  }
  /* Search Page */
  &.product-special{
    .top-sort-panel{
      margin-top: 0;
      &.bottom{
        margin-top: 45px;
      }
    }
  }

  /* Compare page*/
  table{
    .img-thumb{
      padding: 10px;
    }
  }
  .table-compare{
    &>thead{
      text-transform: uppercase;
    }
    &>tbody{
      &>tr{
        &>td{
          vertical-align: top;
          padding: 10px 15px;
          &:first-child{
            width: 140px;
            border-right: none;
          }
          &:last-child{
            border-right: none;
          }
          &.td-vertical{
            vertical-align: middle;
          }
          &.cart-add{
            padding-top: 30px;
            padding-bottom: 30px;
          }
        }
      }
      & + tbody{
        border-top-width: 1px;
      }
    }
    .price{
      font-family: $font-header;
      margin-bottom: 15px;
      .price-new{
        font-size: 26px;
        white-space: nowrap;
      }
      .price-old{
        font-size: 22px;
        text-decoration: line-through;
        padding-right: 10px;
      }
    }
    .btn-remove-compare{
      font-size: 19px;
    }
    .head-product{
      font-weight: 500;
      margin-bottom: 10px;
      display: inline-block;
    }
    .img-thumb{
      margin-bottom: 12px;
    }
  }
  .table-responsive>.table>tbody>tr>td{
    @media (max-width: 767px){
      white-space: normal;
    }
  }
  /* Compare page*/

  /* Product Page */
  .btn.btn-product, .btn-product{
    width: auto;
    background: transparent;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0;
    border-radius: 0;
    float: none !important;
    .fa{
      padding-right: 10px;
      font-size: 15px;
    }
    &:first-child{
      padding-right: 10px;
      padding-left: 0;
      position: relative;
    }
  }
  .product-price{
    margin-bottom: 22px;
    max-height: 50px;
    .price-new{
      font-size: 32px;
    }
    &:after{
      display: table;
      content: '';
      clear: both;
    }
  }
  .heading-product, .heading.heading-product{
    text-align: left;
    margin-top: 35px;
  }
  table.product-info{
    margin-bottom: 0;
    &>tbody{
      &>tr{
        &>td{
          padding: 5px;
          &:first-child{
            padding-left: 0;
            width: 180px;
          }
        }
      }
    }
  }
  .product-option{
    .control-label{
      font-weight: 500;
      font-size: 15px;
      margin-bottom: 7px;
      & + div{
        &>div:first-child{
          margin-top: 0;
        }
      }
    }
    .form-group {
      margin-bottom: 25px;
    }
  }
  .button-cart {
    margin-top: 40px;
    .quantity-contain{
      margin-left: 30px;
    }
  }
  .stock-status{
    padding: 23px 0 10px 100px;
    font-size: 12px;
    line-height: 12px;
    font-family: $font-header;
    @media (max-width: 991px){
      padding-left: 10px;
    }
  }
  .beauty_outstock {
    padding: 2px 5px;
  }
  .product-gallery{
    .thumb{
      display: block;
      padding: 10px;
      margin-bottom: 25px;
      text-align: center;
    }
    .image-additional{
      padding: 0 15px;
    }
    .owl-carousel .owl-buttons{
      .owl-next{
        right: -7px;
        top: 29%;
      }
      .owl-prev{
        left: -7px;
        top: 29%;
      }
      @media (max-width: 767px){
        .owl-next{
          right: 15px;
          opacity: 1;
        }
        .owl-prev{
          left: 15px;
          opacity: 1;
        }
      }
    }
  }
  .flex-tab{
    display: flex;
    margin-top: 80px;
    .nav-tabs{
      flex: 0 0 165px;
      border-bottom: none;
      &>li{
        width: 100%;
        &.active{
          &>a{
          }
        }
        &>a{
          text-transform: uppercase;
          border-radius: 0;
          text-decoration: none;
          padding-left: 10px;
        }
      }
    }
    .tab-content{
      flex: 1 1 auto;
      margin-left: -3px;
      padding-left: 30px;
      padding-top: 30px;
    }
    @media (max-width: 767px){
      flex-wrap: wrap;
      .nav-tabs{
        flex: 1 1 100%;
      }
      .tab-content{
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
  .tab-specific{
    .table-compare{
      margin-bottom: -1px;
      &>tbody>tr>td:first-child{
        width: 145px;
      }
      thead:first-child>tr:first-child>td{
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  }
  .related-block{
    padding-top: 100px;
    padding-bottom: 60px;
  }
  @media (max-width: 991px) and (min-width: 768px){
    #content .col-sm-6.product-info:nth-child(2n+1){
      clear: none;
    }
    .col-2{
      &>div.row{
        &>div{
          width: 100%;
        }
      }
    }
  }
  .col-3{
    .flex-tab{
      flex-wrap: wrap;
      .nav-tabs{
        flex: 1 1 100%;
      }
      .tab-content{
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    &>div.row{
      &>div{
        width: 100%;
      }
    }
  }
  .at-share-btn{
    margin-right: 15px !important;
  }
  .addthis_inline_share_toolbox{
    margin-top: 10px;
  }
  /* Product Page */

  /* footer */
  footer{
    margin-top: 50px;
    .header_footer{
      text-transform: uppercase;
      margin-bottom: 25px;
      @media (max-width:767px) {
        margin-bottom: 15px;
        margin-top: 35px;
      }
     }
    li{
      padding: 3px 0;
    }
    a{
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
    &.footer-bg{
      @media (max-width:767px) {
        background: none;
      }
    }
    .powered{
      padding-top: 90px;
      font-size: 13px;
      padding-bottom: 10px;
    }
  }
  /* footer */

  /* DEFAULT MODULE --*/
  /* Featured module */
  #column-left, #column-right{
    .item-block{
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
  .item-block{
    padding-top: 50px;
    padding-bottom: 50px;
    @media (max-width: 767px){
      padding-top: 35px;
      padding-bottom: 35px;
    }
  }
  .slider-prod-owl{
    margin-bottom: 0;
    .product-layout{
      padding-left: 15px;
      padding-right: 15px;
      max-width: 293px;
      margin: 0 auto;
    }
    .owl-wrapper-outer{
      border: none;
      box-shadow: none;
      border-radius: 0;
    }
    &.owl-carousel{
      .owl-buttons{
        .owl-next{
          top: 28%;
          @media (max-width: 767px){
            opacity: 1;
          }
        }
        .owl-prev{
          top: 28%;
          @media (max-width: 767px){
            opacity: 1;
          }
        }
      }
    }
  }

  #column-left, #column-right, .showcase-left, .col-3{
    .owl-carousel {
      .owl-buttons {
        .owl-next{
          right: 20px;
        }
        .owl-prev{
          left: 20px;
        }
      }
    }
  }
  .owl-carousel{
    .owl-buttons{
      .owl-next, .owl-prev{
        border-radius: 50%;
        height: 31px;
        width: 31px;
        text-align: center;
        .fa{
          line-height: 20px;
          margin: 0;
          padding: 4px 0;
        }
      }
      .owl-next{
        right: 20px;
      }
      .owl-prev{
        left: 20px;
      }
      @media (min-width: 1260px){
        .owl-next{
          right: -30px;
        }
        .owl-prev{
          left: -30px;
        }
      }
    }
  }
  .content-fullup, .content-fullunder{
    .owl-carousel{
      .owl-buttons{
        @media (min-width: 1260px){
          .owl-next{
            right: 20px;
          }
          .owl-prev{
            left: 20px;
          }
        }
      }
    }
  }
  .featured{
    .product-layout{
      max-width: 195px;
    }
    .product-thumb{
      .price{
        margin-bottom: 0;
        padding-top: 5px;
      }
    }
  }
  #column-left, #column-right{
    .item-block{
      &:first-child{
        padding-top: 0;
      }
    }
  }
  .rating{
    &>.fa{
      height: 14px;
      line-height: 14px;
    }
    .fa{
      font-size: 13px;
      width: 14px;
    }
    .fa-stack > .fa-star-o:first-child{
      color: #ddd;
    }
  }
  /* Featured module */

  /* slideshow module*/
  .slideshow:first-child{
    padding-top: 0;
  }

  .slider{
    margin-bottom: 0;
    img{
      margin: 0 auto;
    }
    .owl-wrapper-outer{
      border: none;
      box-shadow: none;
      border-radius: 0;
      .img-responsive {
            margin: 0 auto;
        }
    }
    .owl-pagination{
      position: absolute;
      left: 0;
      right: 0;
      bottom: 15px;
      top: auto;
      .owl-page{
        margin: 0px 3px;
        border-radius: 50%;
        box-shadow: 3px 8px 14px -2px #000000;
        &.active span{
          background: transparent;
          border: 2px solid #fff;
          width: 12px;
          height: 12px;
        }
        span{
          background: #fff;
          border: 2px solid transparent;
          box-shadow: none;
          width: 10px;
          height: 10px;
        }
      }
      @media (max-width: 767px){
        bottom: 5px;
      }
    }
  }
  /* slideshow module*/

  /* account module */
  .account-module{
    padding: 20px 15px;
    .list-group-item{
      border: none;
      text-decoration: none;
      padding: 5px 15px;
      font-weight: 500;
      &:first-child{
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      &:last-child{
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:hover{
        background: transparent;
      }
    }
  }
  /* account module */

  /* category module */
  .list-module{
    padding: 0;
    margin-bottom: 0;
    .list-group-item{
      border: none;
      text-decoration: none;
      padding: 5px 0;
      font-weight: 500;
      &:first-child{
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      &:last-child{
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:hover, &.active{
        background: transparent;
      }
    }
    .child{
      font-weight: 400;
      padding-left: 15px;
    }
  }
  .category-module + .filter-module{
    padding-top: 0 !important;
  }
  .category-title{
    display: block;
    font-weight: 700;
    font-size: 16px;
    padding-bottom: 15px;
  }
  .category-module{
    .list-module{
      padding: 0;
    }
  }
  /* category module */

  /* filter module */
  .filter{
    .filter-group-item{
      display: block;
      font-weight: 700;
      text-decoration: none;
      font-size: 16px;
      padding-bottom: 15px;
      position: relative;
      padding-right: 15px;
      &:after{
        font-family: FontAwesome;
        content: '\f106';
        position: absolute;
        right: 0;
        top: 0;
        font-weight: 500;
        font-size: 18px;
      }
      &.collapsed, &.open{
        &:after{
          content: '\f107';
        }
      }
      &.open{
        &:after{
          content: '\f106';
        }
      }
      &.collapsed.open{
        &:after{
          content: '\f107';
        }
      }

    }
    .checkbox{
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 4px;
      padding-bottom: 4px;
    }
    .filter-container{
      display: none;
    }
    .all_filter{
      font-size: 13px;
      margin: 20px 0;
      -webkit-transition: .3s;
      -moz-transition: .3s;
      -ms-transition: .3s;
      -o-transition: .3s;
      transition: .3s;
      &:hover{
        cursor: pointer;
        opacity: .8;
      }
    }
    .close_all_filter{
      display: none;
    }
  }
  /* filter module */
  /* --DEFAULT MODULE */

  /* showcase */
  .showcase{
    text-align: center;
    .nav-tabs{
      margin-bottom: 50px;
      &>li{
        display: inline-block;
        float: none;
        &.active{
          &>a{
            border: none;
            &:hover{
              border: none;
            }
          }
        }
        &>a{
          border-radius: 0;
          border: none;
          text-decoration: none;
          text-transform: uppercase;
          padding: 5px 10px;
          margin: 2px 15px;
        }
      }
    }
    [class*="-layout"]{
      padding-right: 15px;
      padding-left: 15px;
      max-width: 293px;
      margin: 0 auto;
    }
    .product-thumb{
      .image{
        height: 263px;
        &>a{
          line-height: 240px;
        }
        img{
          max-height: 100%;
        }
      }
    }

    // information view
    .flex-descript{
      padding: 15px;
      margin-bottom: 20px;
      text-align: left;
      overflow: hidden;
      .descript{
        font-weight: 400;
        line-height: 18px;
      }
      .head-product{
        margin-top: 0;
        height: 44px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    //carusel - fixed height
    .slider-prod-owl.owl-carousel .owl-buttons{
      .owl-next, .owl-prev{
        top: 130px;
      }
    }
  }
  // media
  #content.col-sm-12{
    .content-top, .content_bottom{
      .slider-prod-owl{
        @media (min-width: 992px) and (max-width: 1200px){
          padding-left: 60px;
          padding-right: 60px;
        }
        @media (min-width: 768px) and (max-width: 991px){
          padding-left: 90px;
          padding-right: 90px;
        }
        @media (min-width: 577px) and (max-width: 767px){
          padding-left: 50px;
          padding-right: 50px;
        }
        @media (max-width:576px) {
        }
      }
    }
  }
  .content-up.col-sm-12, .content-under.col-sm-12, .content-fullup{
      .slider-prod-owl{
        @media (min-width: 992px) and (max-width: 1200px){
          padding-left: 60px;
          padding-right: 60px;
        }
        @media (min-width: 768px) and (max-width: 991px){
          padding-left: 90px;
          padding-right: 90px;
        }
        @media (min-width: 577px) and (max-width: 767px){
          padding-left: 50px;
          padding-right: 50px;
        }
        @media (max-width:576px) {
        }
      }
  }
  /* showcase */

  /* showcase all*/
  .showcase-all{
    .wrap-panel{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      [class*="-layout"]{
        margin: 0;
        margin-top: 50px;
        flex: 0 0 292px;
      }
    }
    .nav-tabs{
      margin-bottom: 0;
    }
  }
  #column-right, #column-left{
    .showcase-all {
      .wrap-panel {
        [class*="-layout"] {
          max-width: 100%;
        }
      }
    }
  }
  /* showcase all*/

  /* showcase auto height */
  .showcase_auto_height{
    .product-thumb{
      .image{
        min-height: 140px;
        height: auto;
        &>a{
          line-height: 20px;
        }
      }
    }
    .information-sm{
      min-height: 140px;
      .descript{
        line-height: 17px;
        height: 70px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    //carusel - auto height
    .slider-prod-owl.owl-carousel .owl-buttons{
      .owl-next, .owl-prev{
        top: 30%;
      }
    }
  }
  /* showcase auto height */

  /* showcase left */
  .showcase-left{
    .nav-tabs{
      margin-bottom: 0;
      padding-right: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 260px;
      @media (min-width: 768px) and (max-width:1200px){
        padding-right: 0;
      }
      @media (max-width:767px) {
        padding-right: 0;
        flex-direction: row;
        min-height: 30px;
        margin-bottom: 50px;
      }
      &>li{
        margin: 13px 0;
        display: block;
        &>a{
         display: inline-block;
        }
        @media (max-width:767px){
          margin: 0;
        }
      }
    }
    &:after{
      content: '';
      display: table;
      clear: both;
    }
  }
  .col-3, .col-2, #column-right, #column-left{
    .showcase-left{
      .nav-tabs{
        padding-right: 0;
      }
    }
  }
  #column-right, #column-left{
    .showcase-left{
      .nav-tabs{
        width: 100%;
        padding-right: 0;
        flex-direction: row;
        min-height: 30px;
        margin-bottom: 50px;
        &>li{
          margin: 0;
        }
      }
      .tab-content{
        width: 100%;
      }
    }
  }
  .col-3{
    @media  (max-width:1200px){
      .showcase-left{
        .nav-tabs{
          width: 100%;
          padding-right: 0;
          flex-direction: row;
          min-height: 30px;
          margin-bottom: 50px;
          &>li{
            margin: 0;
          }
        }
        .tab-content{
          width: 100%;
        }
      }
    }
  }
  /* showcase left */

  /* showcase nopadding */
  .showcase_nopadding{
    .slider-prod-owl{
      .owl-wrapper-outer{
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: content-box;
      }
      .product-thumb .image{
        //background-color: transparent;
      }
    }
    [class*="-layout"]{
      padding-left: 1px;
      padding-right: 1px;
      margin-bottom: 15px;
      position: relative;
      .product-thumb{
        .caption{
          position: absolute;
          z-index: 2;
          bottom: 0;
          left: 0;
          right: 0;
          .head-product{
            min-height: 20px;
            margin-bottom: 10px;
          }
          .price{
            margin-bottom: 5px;
          }
        }
        .image{
          padding: 15px 15px 40px 15px;
          background-color: transparent;
          &>a{
            line-height: 200px;
          }
          img{
            max-height: 200px;
          }
        }
      }
      &.product-layout{
        .product-thumb{
          .caption{
            .head-product{
              display: none;
            }
          }
          .btn-absolute{
            bottom: 35px;
          }
        }
      }
      &.information-layout{
        .product-thumb{
          .caption{
            position: relative;
            .button-group{
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              padding: 2px 15px 15px 15px;
              text-align: left;
              background: transparent;
            }
          }
        }
      }
    }
    .owl-item{
      &:hover{
        z-index: 9999;
        position: relative;
      }
    }
  }
  #content.col-sm-12 {
    .content-top, .content_bottom {
      .showcase_nopadding .slider-prod-owl {
        @media (min-width: 992px) {
          padding-left: 30px;
          padding-right: 30px;
        }
        @media (min-width: 768px) and (max-width: 991px) {
          padding-left: 70px;
          padding-right: 70px;
        }
        @media (min-width: 577px) and (max-width: 767px) {
          padding-left: 35px;
          padding-right: 35px;
        }
        @media (max-width: 576px) {
        }
      }
    }
  }
  .slider-prod-owl{
    margin: 0 auto;
  }
  #column-right, #column-left, .col-3{
    .showcase_nopadding {
      [class*="-layout"] {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
  /* showcase nopadding */

  /* showcase nopadding all*/
  .showcase_all_nopadding{
    .wrap-panel{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      [class*="-layout"]{
        padding-left: 1px;
        padding-right: 1px;
        margin: 1px 0;
        flex: 0 0 262px;
        .product-thumb{
          position: relative;
          .caption{
            position: absolute;
            z-index: 2;
            bottom: 0;
            left: 0;
            right: 0;
            margin-bottom: 0;
            .head-product{
              min-height: 20px;
              margin-bottom: 10px;
            }
            .price{
              margin-bottom: 5px;
            }
          }
          .image{
            background-color: transparent;
          }
        }
        &.product-layout{
          .product-thumb{
            .caption{
              .head-product{
                display: none;
              }
            }
            .btn-absolute{
              bottom: 35px;
            }
          }
        }
        &.information-layout{
          .product-thumb{
            .caption{
              position: relative;
              .button-group{
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 2px 15px 15px 15px;
                text-align: left;
                background-color: transparent;
              }
            }
            .flex-descript{
              margin-bottom: 0;
            }
          }
        }
        &:hover{
          z-index: 9999;
          position: relative;
        }
      }
    }
  }
  #column-right, #column-left{
    .showcase_all_nopadding {
      .wrap-panel {
        [class*="-layout"] {
          max-width: 100%;
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }
  /* showcase nopadding all*/

  /* showcase gorisontal view ,showcase gorisontal view all*/
  .showcase_gorisontal_view, .showcase_gorisontal_view_all{
    [class*="-layout"]{
      max-width: 390px;
      .product-thumb{
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        .image{
          height: 165px;
          &>a{
            line-height: 140px;
          }
        }
      }
      &:not(.information-layout){
        .caption{
          text-align: left;
          padding-left: 30px;
        }
        .price{
          text-align: left;
        }
        .image{
          flex: 0 0 165px;
        }
      }
      &.information-layout{
        .product-thumb{
          .caption{
            position: relative;
            width: 100%;
            .button-group{
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              padding: 4px 2px 15px 2px;
              text-align: left;
              padding-left: 15px;
            }
            .descript{
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }
    //carusel - fixed height
    .slider-prod-owl.owl-carousel .owl-buttons{
      .owl-next, .owl-prev{
        top: 80px;
      }
    }
  }
  #column-right, #column-left{
    .showcase_gorisontal_view, .showcase_gorisontal_view_all{
      @media (max-width:1200px){
        [class*="-layout"] {
          .product-thumb {
            flex-direction: row;
            flex-wrap: wrap;
            .image{
              width: 100%;
            }
          }
          &:not(.information-layout){
            .caption{
              width: 100%;
            }
            .image{
              flex: 1 0 100%;
            }
          }
        }
      }
    }
  }
  /* showcase gorisontal view, showcase gorisontal view all*/

  /* showcase gorisontal view all */
  .showcase_gorisontal_view_all{
    .wrap-panel {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      [class*="-layout"]{
        flex: 1 0 360px;
        margin: 0;
        @media (max-width:400px) {
          max-width: 100%;
        }
      }
      @media (max-width:767px) {
        .flex-descript{
          margin-bottom: 0;
        }
      }
    }
  }
  #column-right, #column-left{
    .showcase_gorisontal_view_all{
      .wrap-panel {
        [class*="-layout"] {
          max-width: 100%;
        }
      }
    }
  }
  /* showcase gorisontal view all */



  /* socials module */

  .socials{
    .at-share-tbx-element .at-share-btn {
      transform: none;
    }
    a{
      margin: 10px 5px;
      display: inline-block;
      &:hover{
        text-decoration: none;
      }
    }
    .fa{
      font-size: 22px;
    }
  }
  /* socials module */

  /* advancedBanners Module*/
  .content-up, .content-under, .content-top, .content-bottom{
    .advanced-banners{
      margin: 0 -15px;
    }
  }
  .content-fullunder, .content-fullup{
    .advanced-banners .advanced-container{
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .content-fullunder {
    .advanced-banners {
      padding: 55px 15px 40px 15px;
    }
  }
  .advanced-banners{
    padding-left: 15px;
    padding-right: 15px;
    .advanced-container{
      margin: 0 auto;
      @media (min-width: 320px){
        max-width: 420px;
      }
      @media (min-width: 768px){
        max-width: 750px;
      }
      @media (min-width: 992px){
        max-width: 970px;
      }
      @media (min-width: 1200px){
        max-width: 1170px;
      }
    }
    .heading-module{
      padding-bottom: 30px;
      span{
        background-color: transparent;
      }
    }
    .advanced-column{
      float: left;
      height: 341px;
      padding: 15px;
     .container-img{
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
        overflow: hidden;
        .text{
          position: absolute;
          z-index: 99;
          text-align: center;
          display: inline-block;
          padding: 40px 35px;
          width: 100%;
          font-size: 22px;
          font-weight: 300;
          line-height: 22px;
          &.text_top{
            top: 0;
          }
          &.text_bottom{
            top: auto;
            bottom: 0;
          }
        }
      }
      img{
        max-width: 100%;
        //max-height: 100%;
        position: absolute;
        width: auto;
        height: auto;
        right: 0;
        left: 0;
        margin: 0 auto;
      }
      &:not(.resize-img){
        img{
          max-height: 100%;
        }
        .container-img{
          background-image: none !important;
        }
      }
      &.col-first{
        width: 41.66666667%;
      }
      &.col-second{
        width: 58.33333333%;
        float: right;
        height: 682px;
      }
      &.col-third{
        width: 41.66666667%;
      }
    }

    // carusel
    .advanced-carusel{
      height: 100%;
      transition: .3s;
      .owl-wrapper-outer, .owl-wrapper, .owl-item{
        height: 100%;
      }
    }
    // view - resize img
    .resize-img{
      &.advanced-column{
        img{
          min-width: 100%;
          min-height: 100%;
          max-width: 110%;
          max-height: 110%;
          -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          transform: translateX(-50%);
          left: 50%;
          display: none;
        }
        .container-img{
          background-size: cover;
          background-position: center;
        }
      }
    }

    // Right view
    &.advanced-right{
      .advanced-column{
        float: right;
        &.col-second{
          float: left;
        }
      }
    }

    // Square view
    &.advanced-square{
      .advanced-column{
        width: 50%;
        height: 585px;
        &.col-second{
          width: 50%;
          height: 585px;
        }
      }
      @media (min-width: 992px) and (max-width:1200px){
        .advanced-column{
          height: 485px;
          &.col-second{
            height: 485px;
          }
        }
      }
      @media (min-width: 768px) and (max-width:991px){
        .advanced-column{
          height: 375px;
          &.col-second{
            height: 375px;
          }
        }
      }
      @media (max-width:767px) {
        .advanced-column{
          height: 320px;
          &.col-first{
            width: 100%;
          }
          &.col-second{
            height: 320px;
            width: 100%;
          }
        }
      }
    }
    // Square rect
    &.advanced-rect{
      .advanced-column{
        width: 50%;
        height: calc(3/4*585px);
        &.col-second{
          width: 50%;
          height: calc(3/4*585px);
        }
      }
      @media (min-width: 992px) and (max-width:1200px){
        .advanced-column{
          height: calc(3/4*485px);
          &.col-second{
            height: calc(3/4*485px);
          }
        }
      }
      @media (min-width: 768px) and (max-width:991px){
        .advanced-column{
          height: calc(3/4*375px);
          &.col-second{
            height: calc(3/4*375px);
          }
        }
      }
      @media (max-width:767px) {
        .advanced-column{
          height: calc(3/4*320px);
          &.col-first{
            width: 100%;
          }
          &.col-second{
            height: calc(3/4*320px);
            width: 100%;
          }
        }
      }
    }

    // media
    @media (min-width: 992px) and (max-width:1200px){
      .advanced-column{
        height: 283px;
        &.col-second{
          height: 566px;
        }
      }
    }
    @media (min-width: 768px) and (max-width:991px){
      .advanced-column{
        height: 218px;
        &.col-second{
          height: 437px;
        }
      }
    }
    @media (max-width:767px) {
      .advanced-column{
        height: 300px;
        &.col-first{
          width: 100%;
        }
        &.col-second{
          height: 450px;
          width: 100%;
        }
        &.col-third{
          width: 100%;
        }
      }
    }
  }
  .col-3{
    .advanced-column{
      height: 218px;
      &.col-first{
        width: 100%;
      }
      &.col-second{
        height: 437px;
        width: 100%;
      }
      &.col-third{
        width: 100%;
      }
    }
  }
  #column-left, #column-right{
    .advanced-column{
      height: 218px;
      &.col-first{
        width: 100%;
      }
      &.col-second{
        height: 280px;
        width: 100%;
      }
      &.col-third{
        width: 100%;
      }
      .container-img .text{
        font-size: 16px;
        padding: 15px;
      }
    }
  }
  .advanced-banners:after{
    content: '';
    display: block;
    clear: both;
  }
  /* advancedBanners Module*/

  /* Labels Module*/
  .product-labels{
    position: absolute;
    list-style-type: none;
    right: 0;
    top: 0;
    text-align: right;
    padding: 15px;
    line-height: 23px;
    &>li{
      display: block;
      &>span{
        border-radius: 3px;
        margin-bottom: 0;
        padding: 4px 5px;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        height: 26px;
        display: -webkit-inline-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-all;
        line-height: 20px;
      }
    }
    &.product-page-labels{
      right: 15px;
      left: 15px;
    }
  }
  /* Labels Module*/

  /* MEGA Menu */
  .megaMenu{
    .navbar-nav{
      &.menu_stage_1{
        &> li {
          &.has-child {
            &:hover{
              &>.sub_menu{
                padding-top: 20px;
                @media (max-width: 991px) and (min-width: 767px){
                  margin-top: 0;
                  padding-top: 0;
                }
                @media (max-width: 767px){
                  padding-top: 0;
                }
              }
            }
            @media (max-width: 991px) and (min-width: 768px) {
              &>.caret-container {
                position: relative;
                display: inline-block;
                &:after {
                  content: '|';
                  position: absolute;
                  right: -1px;
                }
              }
              & > a {
                padding-right: 0;
                display: inline-block;
                &:after {
                  display: none;
                }
              }
            }
            @media (max-width: 767px){
              &>.mob-dropdown{
                position: absolute !important;
                text-decoration: none;
                margin: 0;
              }
            }
          }
        }
      }
      &> li{
        &>a, &>span{
          @media (min-width: 992px){
              display: inline-block;
          }
          &:after{
            right: -1px;
            top: 3px;
          }
          @media (max-width: 767px){
            margin-right: 0 !important;
          }
        }
        &>span{
          @media (max-width: 991px) and (min-width: 767px){
            padding-right: 0;
            &:after{
              display: none;
            }
          }
        }
        span:hover{
          cursor: default;
        }
        @media (max-width: 767px) {
          span {
            color: #fff;
            display: block;
          }
        }
        &:last-child{
          &>a, &>span{
            &:after{
              display: none;
            }
          }
        }
        &.has-child{
          position: static;
          @media (max-width: 767px){
            position: relative;
          }
          .sub_menu{
            left: 0;
            right: 0;
            width: auto;
            min-width: 100px;
            max-width: 100%;
            //margin-left: 25px;
            margin-left: 0;
            margin-bottom: 20px;
            padding-top: 0;
            margin-top: 20px;
            @media (max-width: 991px) and (min-width: 767px){
              top: 100%;
              margin-top: 0;
            }
            .sub_menu_contein{
              padding: 15px;
              box-sizing: content-box;
              &:after{
                display: block;
              }
              @media (max-width: 767px){
                padding: 0 15px;
              }
            }
            @media (max-width: 991px) and (min-width: 767px){
              box-shadow: none;
            }
            .sub_menu{
              position: relative;
              .sub_menu_contein{
                box-shadow: none;
                background: transparent;
                padding: 0;
              }
            }
            a, span{
              display: inline-block;
              word-break: break-word;
              &.all_href_text{
                margin: 10px 15px;
                text-decoration: underline;
                &:hover{
                  text-decoration: none;
                }
              }
            }
            .menu_stage_2{
              &:after{
                content: '';
                display: table;
                clear: both;
              }
              @media (max-width: 767px){
                margin-left: 20px;
              }
              &>li{
                float: left;
                width: 25%;
                display: inline-block;
                padding: 15px;
                &:nth-child(4n+5){
                  clear: left;
                }
                @media (min-width: 992px) and (max-width:1200px){
                  width: 33.3%;
                  &:nth-child(4n+5) {
                    clear: none;
                  }
                  &:nth-child(3n+4) {
                    clear: left;
                  }
                }
                @media (max-width: 991px) and (min-width: 768px) {
                  width: 50%;
                  &:nth-child(4n+5) {
                    clear: none;
                  }
                  &:nth-child(3n+4) {
                    clear: none;
                  }
                  &:nth-child(2n+3) {
                    clear: left;
                  }
                }
                @media (max-width: 767px){
                  width: 100% !important;
                  padding: 8px 15px;
                  float: none;
                }
                &>a, &>span{
                  text-transform: uppercase;
                  display: block;
                  & + .sub_menu{
                    //padding-top: 20px;
                  }
                  @media (max-width: 767px){
                    display: inline-block;
                  }
                }
                &>img{
                  padding-right: 0;
                  margin-bottom: 20px;
                  max-width: 100%;
                  @media (max-width: 767px){
                    padding-right: 10px;
                    margin-bottom: 0;
                    max-width: 35px;
                  }
                }
              }
            }
            .menu_stage_3, .menu_stage_4, .menu_stage_5{
              @media (max-width: 767px){
                margin-left: 10px;
              }
              &>li{
                &.has-child{
                  &>.sub_menu{
                    margin-left: 25px;
                    @media (max-width: 767px){
                      margin-left: 0;
                    }
                  }
                }
              }
            }
          }
          &:hover .sub_menu{
            //padding-top: 10px;
            //padding-bottom: 10px;
          }
          &:hover {
            &>.sub_menu{
              padding-top: 20px;
              margin-top: 0;
            }
            .sub_menu{
              padding-top: 20px;
              margin-top: 0;
              @media (max-width: 991px) and (min-width: 767px){
                margin-top: 20px;
              }
            }
          }
          &>.sub_menu{
            margin-left: 0;
          }
          .sub_menu {
            .menu_stage_2 {
              &>li{
                &>.sub_menu{
                  margin-left: 0;
                }
              }
            }
          }
          @media (max-width: 991px) and (min-width: 768px) {
            .caret-container {
              display: none;
            }
          }
          @media (max-width: 767px){
            .mob-dropdown{

            }
          }
        }
        &.has-image {
          .sub_menu {
            .menu_stage_2 {
              & > li {
                width: 33.3%;
                &:nth-child(4n+5) {
                  clear: none;
                }
                &:nth-child(3n+4) {
                  clear: left;
                }
                @media (min-width: 992px) and (max-width:1200px){
                  width: 50%;
                  &:nth-child(3n+4) {
                    clear: none;
                  }
                  &:nth-child(2n+3) {
                    clear: left;
                  }
                }
                @media (max-width: 991px) and (min-width: 768px) {
                  width: 100%;
                }
              }
            }
          }
        }
      }
      li{
        &.has-child{
          @media (max-width: 767px){
            position: relative;
            padding-right: 0;
            &.has-image .sub_menu a, .has-image a{
              padding-left: 25px;
            }
          }
        }
        &.has-image{
          img, a{
            display: inline-block;
          }
          img{
            padding-right: 10px;
            max-width: 32px;
          }

          @media (max-width: 767px){
            position: relative;
            img{
              position: absolute;
              left: 0;
              top: 4px;
            }
            &>a{
              padding-left: 25px;
            }
          }
        }
      }
      &.menu_stage_1{
        &>li{
          &.has-image{
            &>img{
              position: absolute;
              top: 80px;
              right: 15px;
              max-width: 300px;
              z-index: 999;
              opacity: 0;
              height: 0;
              transition: opacity 1s;
              padding-right: 0;
              margin-right: 0 !important;
              @media (max-width: 991px) and (min-width: 768px) {
                padding-top: 15px;
                top: 100%;
              }
            }
            @media (max-width:767px){
              & > a, & >img + span {
                padding-left: 55px;
                padding-right: 40px;
              }
              &>img{
                position: absolute;
                opacity: 1;
                height: auto;
                top: 1px !important;
                max-width: 50px !important;
                width: auto;
                max-height: 45px;
                padding-left: 15px;
                left: 0;
              }
              &.active_mob {
                & > a, & > span {

                }
                & > img {
                  position: absolute;
                  opacity: 1;
                  height: auto;
                  top: 1px !important;
                  max-width: 50px !important;
                  width: auto;
                  max-height: 45px;
                  padding-left: 15px;
                  left: 0;
                }
                &:hover{
                  &>img{
                    opacity: 1;
                    display: block;
                  }
                }
              }
              &:hover{
                &>img{

                }
              }
            }
            &>.sub_menu{
              &>.sub_menu_contein{
                padding-right: 300px;
                @media (max-width:767px) {
                  padding-right: 15px;
                  min-height: 10px !important;
                }
              }
            }
            &:hover{
              &>img{
                opacity: 1;
                height: auto;
                @media (max-width: 991px) and (min-width: 768px){
                  opacity: 0;
                  height: 0;
                }
              }
            }
            &.active_sm, &.active_sm:hover{
              &>img{
                @media (max-width: 991px) and (min-width: 768px){
                  opacity: 1;
                  height: auto;
                }
              }
            }
          }
        }
      }
    }
    &>.mobile{
      .navbar-nav{
        @media (max-width: 991px){
           &>li.has-child, &>li{
             img{
               display: none !important;
             }
             &>.sub_menu{
               &>.sub_menu_contein{
                  padding-right: 15px;
               }
               .menu_stage_2 > li{
                 width: 50%;
               }
             }
           }
        }
        @media (max-width:767px) {
          &.menu_stage_1 > li.has-image {
            & > a, &>img+span{
              padding-left: 15px;
            }
          }
        }
      }
    }
  }
  /* MEGA Menu */
  .tooltip {
    z-index: 99999;
  }
}

@media (min-width: 992px) and (max-width:1200px){

}
@media (min-width: 768px) and (max-width:991px){

}
@media (max-width:767px) {

}