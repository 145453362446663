body {
  /* contact page */
  /* contact page */
  /* account page */
  /* account page */
  /* address page */
  /* address page */
  /* cart page */
  /* cart page */
  /* Category Page */
  /* Category Page */
  /* Search Page */
  /* Search Page */
  /* Compare page*/
  /* Compare page*/
  /* Product Page */
  /* Product Page */
  /* footer */
  /* footer */
  /* DEFAULT MODULE --*/
  /* Featured module */
  /* Featured module */
  /* slideshow module*/
  /* slideshow module*/
  /* account module */
  /* account module */
  /* category module */
  /* category module */
  /* filter module */
  /* filter module */
  /* --DEFAULT MODULE */
  /* showcase */
  /* showcase */
  /* showcase all*/
  /* showcase all*/
  /* showcase auto height */
  /* showcase auto height */
  /* showcase left */
  /* showcase left */
  /* showcase nopadding */
  /* showcase nopadding */
  /* showcase nopadding all*/
  /* showcase nopadding all*/
  /* showcase gorisontal view ,showcase gorisontal view all*/
  /* showcase gorisontal view, showcase gorisontal view all*/
  /* showcase gorisontal view all */
  /* showcase gorisontal view all */
  /* socials module */
  /* socials module */
  /* advancedBanners Module*/
  /* advancedBanners Module*/
  /* Labels Module*/
  /* Labels Module*/
  /* MEGA Menu */
  /* MEGA Menu */
}

body .form-contact legend {
  font-size: 18px;
  border-bottom: none;
}

body .form-contact .buttons {
  margin-top: 15px;
}

body label.control-label {
  font-size: 13px;
  font-weight: 400;
}

body label.control-label.label-textarea {
  padding-top: 50px;
}

@media (max-width: 767px) {
  body label.control-label.label-textarea {
    padding-top: 7px;
  }
}

body .contact-info {
  margin-bottom: 25px;
}

body .contact-info span {
  font-weight: 500;
  margin-bottom: 5px;
  display: inline-block;
}

body .map {
  margin-top: 100px;
}

body .map a {
  text-decoration: none;
}

body .map a:hover {
  text-decoration: underline;
}

body .subtitle {
  padding-bottom: 10px;
  font-size: 18px;
  margin-bottom: 25px;
  margin-top: 0;
}

body .account-list li {
  margin-bottom: 10px;
}

body .buttons {
  margin-top: 60px;
}

@media (max-width: 767px) {
  body .buttons {
    margin-top: 30px;
  }
}

body fieldset + .buttons {
  margin-top: 30px;
}

body .well-border {
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 30px;
  margin-bottom: 20px;
}

body .well-border a {
  font-size: 13px;
  text-decoration: none;
  font-weight: 500;
}

body .well-border label.control-label {
  font-size: 15px;
}

body .well-border h2 + p strong {
  font-weight: 500;
}

body .well-border .btn-submit {
  font-size: 15px;
  font-weight: bold;
}

body .col-3 .col-login {
  width: 100%;
}

body .col-3 .form-width label {
  width: 100%;
  text-align: left;
  padding-bottom: 5px;
}

body .col-3 .form-width label + div[class*='col-'] {
  width: 100%;
}

body .col-3 .form-width .radio-label label {
  width: auto;
}

body .col-3 label.control-label.label-textarea {
  padding-top: 7px;
}

@media (min-width: 768px) and (max-width: 992px) {
  body .col-2 .col-login {
    width: 100%;
  }
  body .col-2 .form-width label {
    width: 100%;
    text-align: left;
    padding-bottom: 5px;
  }
  body .col-2 .form-width label + div [class*='col-'] {
    width: 100%;
  }
  body .col-2 .form-width .radio-label label {
    width: auto;
  }
  body .col-2 label.control-label.label-textarea {
    padding-top: 7px;
  }
}

body fieldset {
  margin-bottom: 40px;
}

body .btn-calendar {
  border: none;
  padding: 5px;
  width: 30px;
}

body .btn-calendar .fa {
  font-size: 18px;
}

body .input-group-btn:last-child > .btn-calendar {
  margin-left: 15px;
}

body .form-horizontal .radio-label .radio-inline {
  padding-top: 0;
}

body .form-label {
  margin-top: 40px;
}

body .table-border {
  margin-bottom: 50px;
}

body .table-border thead:first-child > tr:first-child > td, body .table-border tbody:first-child > tr:first-child > td {
  padding: 10px 15px;
}

body .table-border thead > tr > td, body .table-border tbody > tr > td {
  padding: 15px;
  vertical-align: middle;
  min-width: 115px;
}

body .table-border thead > tr > td.td-remove, body .table-border tbody > tr > td.td-remove {
  width: 50px;
  min-width: 50px;
}

body .table-border .price, body .table-border .total {
  font-size: 18px;
  font-family: "Yeseva One", cursive;
  margin-bottom: 0;
}

body .table-border .stock {
  font-size: 13px;
}

body .table-no-border > tbody > tr > td {
  border-top: none;
}

body .table-no-border > tbody > tr > td.td-remove {
  width: 50px;
  min-width: 50px;
}

body .table-wishlist {
  margin-bottom: 15px;
}

body .address-edit {
  padding-top: 7px;
}

body .address-table {
  margin: 0;
}

body .address-table .text-left {
  padding: 20px;
}

body .address-table .text-right {
  width: 65px;
  vertical-align: middle;
  text-align: center;
}

body .address-table .text-right a {
  display: block;
  font-size: 17px;
  padding: 8px;
}

body .product-quant {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  padding-left: 15px;
  font-weight: 400;
}

@media (max-width: 767px) {
  body .product-quant {
    display: block;
    padding-left: 0;
  }
}

body .img-thumb {
  border: 0;
  border-radius: 0;
}

body .quantity-contain {
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

body .quantity-contain input {
  border: none;
  text-align: center;
  height: 30px;
}

body .quantity-contain input[disabled] {
  width: 28px;
  margin: 0 20px;
}

body .quantity-contain span {
  height: 30px;
  width: 15px;
  border-radius: 3px;
  display: inline-block;
  font-size: 10px;
  padding: 8px 3px;
}

body .quantity-contain span:hover {
  cursor: pointer;
}

body .module-cart {
  display: block;
}

body .module-cart .input-group-btn {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  width: auto;
  opacity: 0;
  z-index: 2;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -ms-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

body .module-cart .input-group-btn .btn {
  border-radius: 0;
  font-size: 13px;
  font-weight: normal;
  letter-spacing: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}

body .module-cart.input-group .form-control {
  margin-bottom: 15px;
  float: none;
}

body .module-cart.input-group .form-control::placeholder {
  text-transform: uppercase;
}

body .module-cart.input-group .form-control:-moz-placeholder {
  text-transform: uppercase;
}

body .module-cart.input-group .form-control:-ms-input-placeholder {
  text-transform: uppercase;
}

body .module-cart.input-group .form-control::-moz-placeholder {
  text-transform: uppercase;
}

body .module-cart.input-group .form-control::-webkit-input-placeholder {
  text-transform: uppercase;
}

body .module-cart.input-group .form-control:focus {
  font-size: 13px;
  padding-right: 100px;
}

body .module-cart.input-group .form-control:focus::placeholder {
  font-size: 13px;
  text-transform: none;
}

body .module-cart.input-group .form-control:focus:-moz-placeholder {
  font-size: 13px;
  text-transform: none;
}

body .module-cart.input-group .form-control:focus:-ms-input-placeholder {
  font-size: 13px;
  text-transform: none;
}

body .module-cart.input-group .form-control:focus::-moz-placeholder {
  font-size: 13px;
  text-transform: none;
}

body .module-cart.input-group .form-control:focus::-webkit-input-placeholder {
  font-size: 13px;
  text-transform: none;
}

body .module-cart.input-group .form-control:focus + .input-group-btn {
  opacity: 1;
}

body .table-total {
  margin-top: 10px;
  margin-bottom: 0;
}

body .table-total > tbody > tr:last-child > td:first-child {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
}

body .table-total > tbody > tr:last-child > td:last-child {
  font-size: 22px;
  font-family: "Yeseva One", cursive;
  width: 130px;
}

@media (max-width: 767px) {
  body .table-total > tbody > tr:last-child > td:last-child {
    width: 100%;
  }
}

@media (max-width: 767px) {
  body .table-total > tbody > tr > td {
    display: block;
    text-align: left;
  }
}

body .table-total > tbody > tr > td:first-child {
  font-weight: 500;
}

@media (max-width: 767px) {
  body .button-col {
    width: 100%;
    float: none;
    text-align: center;
    margin-bottom: 10px;
  }
}

body .panel-group .panel-shipping {
  margin-bottom: 10px;
}

body .heading-map {
  text-transform: uppercase;
  font-size: 15px;
  margin-bottom: 15px;
  display: inline-block;
}

body .page-map ul {
  padding-left: 0;
  list-style-type: none;
}

body .page-map ul li a {
  text-decoration: none;
  line-height: 35px;
}

body .page-map ul li a:hover {
  text-decoration: underline;
}

body .page-map ul li ul {
  padding-left: 20px;
}

body .page-map ul li ul.list-map {
  padding-left: 0;
}

body .col-3 .col-map {
  min-width: 50%;
}

body .not-found {
  font-family: "Yeseva One", cursive;
  font-size: 70px;
}

@media (max-width: 767px) {
  body .not-found {
    font-size: 50px;
  }
}

body .not-found span {
  font-size: 140px;
  display: block;
  line-height: 110px;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  body .not-found span {
    font-size: 90px;
  }
}

body .information p {
  margin-bottom: 25px;
}

body .heading-sm {
  font-size: 18px;
}

body .description-category-container {
  margin-bottom: 30px;
}

body .description-category-container:after {
  content: '';
  display: table;
  clear: both;
}

body .description-category-container .category-img {
  margin-right: 30px;
  margin-bottom: 10px;
  float: left;
}

body .description-category-container .category-img img {
  max-width: 262px;
}

@media (max-width: 480px) {
  body .description-category-container .description-category {
    clear: left;
  }
}

body .sub_category {
  margin-top: 15px;
  margin-bottom: 35px;
  display: flex;
  flex-wrap: wrap;
}

body .sub_category li {
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -ms-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  padding: 10px;
  border-radius: 3px;
  flex: 0 1 142px;
  margin: 15px 15px;
}

@media (max-width: 767px) {
  body .sub_category li {
    flex: 0 1 130px;
  }
}

body .sub_category .sub_category_name {
  display: block;
  padding: 15px 5px 5px 5px;
  word-break: break-word;
  font-size: 14px;
}

body .sub_category .sub_category_name span {
  font-size: 13px;
}

body .sub_category a {
  text-decoration: none;
}

body .sub_category img {
  margin: 0 auto;
  display: block;
}

body .top-sort-panel {
  padding-top: 15px;
  margin-top: 25px;
}

body .top-sort-panel .input-group-addon {
  background: transparent;
  border: none;
  font-size: 15px;
  width: auto;
  padding-right: 15px;
}

body .top-sort-panel:after {
  content: '';
  display: table;
  clear: both;
}

body .top-sort-panel select {
  min-width: 67px;
  max-width: 165px;
  border-radius: 3px !important;
}

body .top-sort-panel .btn-view {
  padding-right: 20px;
}

body .top-sort-panel .btn-view .btn {
  font-size: 16px;
}

body .top-sort-panel .pagination {
  margin: 0 0 10px 0;
}

body .top-sort-panel.bottom {
  margin-top: 45px;
}

body .product-grid {
  margin-top: 30px;
  margin-bottom: 30px;
}

body .product-grid .product-thumb_descript {
  display: none;
}

body .product-grid .btn-list {
  display: none;
}

body .product-grid .button-group {
  margin-top: 10px;
}

body .product-list {
  margin-top: 30px;
}

body .product-list .product-thumb {
  display: flex;
  flex-wrap: wrap;
}

body .product-list .product-thumb .image {
  margin-right: 30px;
  flex: 0 0 260px;
}

body .product-list .product-thumb .caption {
  text-align: left;
  flex: 1 0 300px;
}

body .product-list .product-thumb .caption:after {
  content: '';
  display: table;
  clear: both;
}

body .product-list .product-thumb .caption .rating {
  float: left;
  margin-right: 25px;
  padding-top: 5px;
}

body .product-list .product-thumb .product-thumb_manufacturer {
  display: none;
}

body .product-list .product-thumb .product-thumb_descript {
  font-weight: 400;
}

body .product-list .product-thumb .price {
  float: left;
  margin-bottom: 40px;
}

body .product-list .product-thumb .price .price-new {
  margin-right: 25px;
}

body .product-list .product-thumb .price + .button-group {
  float: left;
}

body .product-list .product-thumb .btn-absolute .wishlist, body .product-list .product-thumb .btn-absolute .compare {
  display: none;
}

body .product-list .product-thumb .btn-list {
  clear: both;
}

body .product-list .product-thumb .btn-list .btn-round {
  width: auto;
  background: transparent;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  border-radius: 0;
}

body .product-list .product-thumb .btn-list .btn-round .fa {
  padding-right: 10px;
  font-size: 15px;
}

body .product-list .product-thumb .btn-list .btn-round:first-child {
  padding-right: 12px;
  margin-right: 5px;
  position: relative;
}

body .product-list .product-thumb .btn-list .btn-round:first-child:after {
  content: '|';
  position: absolute;
  right: 0;
}

body .product-list .product-thumb:after {
  content: '';
  display: table;
  clear: both;
}

body .product-thumb:hover .btn-absolute {
  opacity: 1;
}

@media (max-width: 767px) {
  body .product-thumb:hover .btn-absolute {
    display: none;
  }
}

body .product-thumb:hover .image {
  -webkit-transition: .5s;
  -moz-transition: .5s;
  -ms-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
}

body .product-thumb .image {
  -webkit-transition: .5s;
  -moz-transition: .5s;
  -ms-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
  padding: 10px;
  text-align: center;
  position: relative;
  border-radius: 3px;
  min-height: 100px;
}

body .product-thumb .image > a {
  display: block;
  width: 100%;
}

body .product-thumb .caption {
  font-weight: 500;
  margin-bottom: 0;
}

body .product-thumb .caption a {
  text-decoration: none;
}

body .product-thumb .caption .head-product {
  display: inline-block;
  margin-top: 15px;
  min-height: 43px;
}

@media (max-width: 767px) {
  body .product-thumb .caption .head-product {
    min-height: 15px;
  }
}

body .product-thumb .caption .head-product a:hover {
  text-decoration: underline;
}

body .product-thumb .caption .product-thumb_manufacturer {
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
}

body .product-thumb .btn-absolute {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  margin-bottom: 15px;
  opacity: 0;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  -ms-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
}

body .price {
  font-family: "Yeseva One", cursive;
  margin-bottom: 0;
}

body .price .price-new {
  font-size: 26px;
}

body .price .price-new span {
  font-size: 16px;
}

body .price .price-old {
  font-size: 22px;
  text-decoration: line-through;
  padding-right: 10px;
}

body .price .price-old span {
  display: none;
}

body .price > span {
  display: inline-block;
}

body .show-more {
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 10px;
}

body .product-thumb .btn-round.btn-view .fa {
  line-height: 26px;
}

body .products-category .product-thumb .image {
  max-width: 260px;
  margin: 0 auto;
}

body .products-category .product-list .product-thumb .image {
  margin-right: 30px;
}

@media (min-width: 768px) and (max-width: 991px) {
  body .products-category .product-list .product-thumb .image {
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  body #content .col-lg-2:nth-child(6n+1),
  body #content .col-lg-2:nth-child(6n+1),
  body #content .col-lg-3:nth-child(4n+1),
  body #content .col-lg-4:nth-child(3n+1),
  body #content .col-lg-6:nth-child(2n+1) {
    clear: left;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  body #content .col-md-2:nth-child(6n+1),
  body #content .col-md-2:nth-child(6n+1),
  body #content .col-md-3:nth-child(4n+1),
  body #content .col-md-4:nth-child(3n+1),
  body #content .col-md-6:nth-child(2n+1) {
    clear: left;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  body #content .col-sm-2:nth-child(6n+1),
  body #content .col-sm-2:nth-child(6n+1),
  body #content .col-sm-3:nth-child(4n+1),
  body #content .col-sm-4:nth-child(3n+1),
  body #content .col-sm-6:nth-child(2n+1) {
    clear: left;
  }
}

body .pagination {
  width: 100%;
}

body .pagination > li > a, body .pagination > li > span {
  border: none;
  margin: 0 4px;
  border-radius: 3px;
  font-size: 13px;
  padding-left: 11px;
  padding-right: 11px;
}

body .col-3 .top-sort-panel .input-group-addon {
  display: none;
}

body.product-search h2.heading {
  text-align: left;
  padding-bottom: 0;
}

body.product-search .checkbox-inline {
  font-size: 13px;
}

body.product-search .buttons {
  margin-top: 30px;
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  body.product-search input.form-control {
    margin-bottom: 10px;
  }
}

body.product-special .top-sort-panel {
  margin-top: 0;
}

body.product-special .top-sort-panel.bottom {
  margin-top: 45px;
}

body table .img-thumb {
  padding: 10px;
}

body .table-compare > thead {
  text-transform: uppercase;
}

body .table-compare > tbody > tr > td {
  vertical-align: top;
  padding: 10px 15px;
}

body .table-compare > tbody > tr > td:first-child {
  width: 140px;
  border-right: none;
}

body .table-compare > tbody > tr > td:last-child {
  border-right: none;
}

body .table-compare > tbody > tr > td.td-vertical {
  vertical-align: middle;
}

body .table-compare > tbody > tr > td.cart-add {
  padding-top: 30px;
  padding-bottom: 30px;
}

body .table-compare > tbody + tbody {
  border-top-width: 1px;
}

body .table-compare .price {
  font-family: "Yeseva One", cursive;
  margin-bottom: 15px;
}

body .table-compare .price .price-new {
  font-size: 26px;
  white-space: nowrap;
}

body .table-compare .price .price-old {
  font-size: 22px;
  text-decoration: line-through;
  padding-right: 10px;
}

body .table-compare .btn-remove-compare {
  font-size: 19px;
}

body .table-compare .head-product {
  font-weight: 500;
  margin-bottom: 10px;
  display: inline-block;
}

body .table-compare .img-thumb {
  margin-bottom: 12px;
}

@media (max-width: 767px) {
  body .table-responsive > .table > tbody > tr > td {
    white-space: normal;
  }
}

body .btn.btn-product, body .btn-product {
  width: auto;
  background: transparent;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  border-radius: 0;
  float: none !important;
}

body .btn.btn-product .fa, body .btn-product .fa {
  padding-right: 10px;
  font-size: 15px;
}

body .btn.btn-product:first-child, body .btn-product:first-child {
  padding-right: 10px;
  padding-left: 0;
  position: relative;
}

body .product-price {
  margin-bottom: 22px;
  max-height: 50px;
}

body .product-price .price-new {
  font-size: 32px;
}

body .product-price:after {
  display: table;
  content: '';
  clear: both;
}

body .heading-product, body .heading.heading-product {
  text-align: left;
  margin-top: 35px;
}

body table.product-info {
  margin-bottom: 0;
}

body table.product-info > tbody > tr > td {
  padding: 5px;
}

body table.product-info > tbody > tr > td:first-child {
  padding-left: 0;
  width: 180px;
}

body .product-option .control-label {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 7px;
}

body .product-option .control-label + div > div:first-child {
  margin-top: 0;
}

body .product-option .form-group {
  margin-bottom: 25px;
}

body .button-cart {
  margin-top: 40px;
}

body .button-cart .quantity-contain {
  margin-left: 30px;
}

body .stock-status {
  padding: 23px 0 10px 100px;
  font-size: 12px;
  line-height: 12px;
  font-family: "Yeseva One", cursive;
}

@media (max-width: 991px) {
  body .stock-status {
    padding-left: 10px;
  }
}

body .beauty_outstock {
  padding: 2px 5px;
}

body .product-gallery .thumb {
  display: block;
  padding: 10px;
  margin-bottom: 25px;
  text-align: center;
}

body .product-gallery .image-additional {
  padding: 0 15px;
}

body .product-gallery .owl-carousel .owl-buttons .owl-next {
  right: -7px;
  top: 29%;
}

body .product-gallery .owl-carousel .owl-buttons .owl-prev {
  left: -7px;
  top: 29%;
}

@media (max-width: 767px) {
  body .product-gallery .owl-carousel .owl-buttons .owl-next {
    right: 15px;
    opacity: 1;
  }
  body .product-gallery .owl-carousel .owl-buttons .owl-prev {
    left: 15px;
    opacity: 1;
  }
}

body .flex-tab {
  display: flex;
  margin-top: 80px;
}

body .flex-tab .nav-tabs {
  flex: 0 0 165px;
  border-bottom: none;
}

body .flex-tab .nav-tabs > li {
  width: 100%;
}

body .flex-tab .nav-tabs > li > a {
  text-transform: uppercase;
  border-radius: 0;
  text-decoration: none;
  padding-left: 10px;
}

body .flex-tab .tab-content {
  flex: 1 1 auto;
  margin-left: -3px;
  padding-left: 30px;
  padding-top: 30px;
}

@media (max-width: 767px) {
  body .flex-tab {
    flex-wrap: wrap;
  }
  body .flex-tab .nav-tabs {
    flex: 1 1 100%;
  }
  body .flex-tab .tab-content {
    padding-left: 10px;
    padding-right: 10px;
  }
}

body .tab-specific .table-compare {
  margin-bottom: -1px;
}

body .tab-specific .table-compare > tbody > tr > td:first-child {
  width: 145px;
}

body .tab-specific .table-compare thead:first-child > tr:first-child > td {
  padding-top: 15px;
  padding-bottom: 15px;
}

body .related-block {
  padding-top: 100px;
  padding-bottom: 60px;
}

@media (max-width: 991px) and (min-width: 768px) {
  body #content .col-sm-6.product-info:nth-child(2n+1) {
    clear: none;
  }
  body .col-2 > div.row > div {
    width: 100%;
  }
}

body .col-3 .flex-tab {
  flex-wrap: wrap;
}

body .col-3 .flex-tab .nav-tabs {
  flex: 1 1 100%;
}

body .col-3 .flex-tab .tab-content {
  padding-left: 10px;
  padding-right: 10px;
}

body .col-3 > div.row > div {
  width: 100%;
}

body .at-share-btn {
  margin-right: 15px !important;
}

body .addthis_inline_share_toolbox {
  margin-top: 10px;
}

body footer {
  margin-top: 50px;
}

body footer .header_footer {
  text-transform: uppercase;
  margin-bottom: 25px;
}

@media (max-width: 767px) {
  body footer .header_footer {
    margin-bottom: 15px;
    margin-top: 35px;
  }
}

body footer li {
  padding: 3px 0;
}

body footer a {
  text-decoration: none;
}

body footer a:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  body footer.footer-bg {
    background: none;
  }
}

body footer .powered {
  padding-top: 90px;
  font-size: 13px;
  padding-bottom: 10px;
}

body #column-left .item-block, body #column-right .item-block {
  padding-top: 30px;
  padding-bottom: 30px;
}

body .item-block {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (max-width: 767px) {
  body .item-block {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}

body .slider-prod-owl {
  margin-bottom: 0;
}

body .slider-prod-owl .product-layout {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 293px;
  margin: 0 auto;
}

body .slider-prod-owl .owl-wrapper-outer {
  border: none;
  box-shadow: none;
  border-radius: 0;
}

body .slider-prod-owl.owl-carousel .owl-buttons .owl-next {
  top: 28%;
}

@media (max-width: 767px) {
  body .slider-prod-owl.owl-carousel .owl-buttons .owl-next {
    opacity: 1;
  }
}

body .slider-prod-owl.owl-carousel .owl-buttons .owl-prev {
  top: 28%;
}

@media (max-width: 767px) {
  body .slider-prod-owl.owl-carousel .owl-buttons .owl-prev {
    opacity: 1;
  }
}

body #column-left .owl-carousel .owl-buttons .owl-next, body #column-right .owl-carousel .owl-buttons .owl-next, body .showcase-left .owl-carousel .owl-buttons .owl-next, body .col-3 .owl-carousel .owl-buttons .owl-next {
  right: 20px;
}

body #column-left .owl-carousel .owl-buttons .owl-prev, body #column-right .owl-carousel .owl-buttons .owl-prev, body .showcase-left .owl-carousel .owl-buttons .owl-prev, body .col-3 .owl-carousel .owl-buttons .owl-prev {
  left: 20px;
}

body .owl-carousel .owl-buttons .owl-next, body .owl-carousel .owl-buttons .owl-prev {
  border-radius: 50%;
  height: 31px;
  width: 31px;
  text-align: center;
}

body .owl-carousel .owl-buttons .owl-next .fa, body .owl-carousel .owl-buttons .owl-prev .fa {
  line-height: 20px;
  margin: 0;
  padding: 4px 0;
}

body .owl-carousel .owl-buttons .owl-next {
  right: 20px;
}

body .owl-carousel .owl-buttons .owl-prev {
  left: 20px;
}

@media (min-width: 1260px) {
  body .owl-carousel .owl-buttons .owl-next {
    right: -30px;
  }
  body .owl-carousel .owl-buttons .owl-prev {
    left: -30px;
  }
}

@media (min-width: 1260px) {
  body .content-fullup .owl-carousel .owl-buttons .owl-next, body .content-fullunder .owl-carousel .owl-buttons .owl-next {
    right: 20px;
  }
  body .content-fullup .owl-carousel .owl-buttons .owl-prev, body .content-fullunder .owl-carousel .owl-buttons .owl-prev {
    left: 20px;
  }
}

body .featured .product-layout {
  max-width: 195px;
}

body .featured .product-thumb .price {
  margin-bottom: 0;
  padding-top: 5px;
}

body #column-left .item-block:first-child, body #column-right .item-block:first-child {
  padding-top: 0;
}

body .rating > .fa {
  height: 14px;
  line-height: 14px;
}

body .rating .fa {
  font-size: 13px;
  width: 14px;
}

body .rating .fa-stack > .fa-star-o:first-child {
  color: #ddd;
}

body .slideshow:first-child {
  padding-top: 0;
}

body .slider {
  margin-bottom: 0;
}

body .slider img {
  margin: 0 auto;
}

body .slider .owl-wrapper-outer {
  border: none;
  box-shadow: none;
  border-radius: 0;
}

body .slider .owl-wrapper-outer .img-responsive {
  margin: 0 auto;
}

body .slider .owl-pagination {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 15px;
  top: auto;
}

body .slider .owl-pagination .owl-page {
  margin: 0px 3px;
  border-radius: 50%;
  box-shadow: 3px 8px 14px -2px #000000;
}

body .slider .owl-pagination .owl-page.active span {
  background: transparent;
  border: 2px solid #fff;
  width: 12px;
  height: 12px;
}

body .slider .owl-pagination .owl-page span {
  background: #fff;
  border: 2px solid transparent;
  box-shadow: none;
  width: 10px;
  height: 10px;
}

@media (max-width: 767px) {
  body .slider .owl-pagination {
    bottom: 5px;
  }
}

body .account-module {
  padding: 20px 15px;
}

body .account-module .list-group-item {
  border: none;
  text-decoration: none;
  padding: 5px 15px;
  font-weight: 500;
}

body .account-module .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

body .account-module .list-group-item:last-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

body .account-module .list-group-item:hover {
  background: transparent;
}

body .list-module {
  padding: 0;
  margin-bottom: 0;
}

body .list-module .list-group-item {
  border: none;
  text-decoration: none;
  padding: 5px 0;
  font-weight: 500;
}

body .list-module .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

body .list-module .list-group-item:last-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

body .list-module .list-group-item:hover, body .list-module .list-group-item.active {
  background: transparent;
}

body .list-module .child {
  font-weight: 400;
  padding-left: 15px;
}

body .category-module + .filter-module {
  padding-top: 0 !important;
}

body .category-title {
  display: block;
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 15px;
}

body .category-module .list-module {
  padding: 0;
}

body .filter .filter-group-item {
  display: block;
  font-weight: 700;
  text-decoration: none;
  font-size: 16px;
  padding-bottom: 15px;
  position: relative;
  padding-right: 15px;
}

body .filter .filter-group-item:after {
  font-family: FontAwesome;
  content: '\f106';
  position: absolute;
  right: 0;
  top: 0;
  font-weight: 500;
  font-size: 18px;
}

body .filter .filter-group-item.collapsed:after, body .filter .filter-group-item.open:after {
  content: '\f107';
}

body .filter .filter-group-item.open:after {
  content: '\f106';
}

body .filter .filter-group-item.collapsed.open:after {
  content: '\f107';
}

body .filter .checkbox {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 4px;
  padding-bottom: 4px;
}

body .filter .filter-container {
  display: none;
}

body .filter .all_filter {
  font-size: 13px;
  margin: 20px 0;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -ms-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

body .filter .all_filter:hover {
  cursor: pointer;
  opacity: .8;
}

body .filter .close_all_filter {
  display: none;
}

body .showcase {
  text-align: center;
}

body .showcase .nav-tabs {
  margin-bottom: 50px;
}

body .showcase .nav-tabs > li {
  display: inline-block;
  float: none;
}

body .showcase .nav-tabs > li.active > a {
  border: none;
}

body .showcase .nav-tabs > li.active > a:hover {
  border: none;
}

body .showcase .nav-tabs > li > a {
  border-radius: 0;
  border: none;
  text-decoration: none;
  text-transform: uppercase;
  padding: 5px 10px;
  margin: 2px 15px;
}

body .showcase [class*="-layout"] {
  padding-right: 15px;
  padding-left: 15px;
  max-width: 293px;
  margin: 0 auto;
}

body .showcase .product-thumb .image {
  height: 263px;
}

body .showcase .product-thumb .image > a {
  line-height: 240px;
}

body .showcase .product-thumb .image img {
  max-height: 100%;
}

body .showcase .flex-descript {
  padding: 15px;
  margin-bottom: 20px;
  text-align: left;
  overflow: hidden;
}

body .showcase .flex-descript .descript {
  font-weight: 400;
  line-height: 18px;
}

body .showcase .flex-descript .head-product {
  margin-top: 0;
  height: 44px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

body .showcase .slider-prod-owl.owl-carousel .owl-buttons .owl-next, body .showcase .slider-prod-owl.owl-carousel .owl-buttons .owl-prev {
  top: 130px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  body #content.col-sm-12 .content-top .slider-prod-owl, body #content.col-sm-12 .content_bottom .slider-prod-owl {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  body #content.col-sm-12 .content-top .slider-prod-owl, body #content.col-sm-12 .content_bottom .slider-prod-owl {
    padding-left: 90px;
    padding-right: 90px;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  body #content.col-sm-12 .content-top .slider-prod-owl, body #content.col-sm-12 .content_bottom .slider-prod-owl {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  body .content-up.col-sm-12 .slider-prod-owl, body .content-under.col-sm-12 .slider-prod-owl, body .content-fullup .slider-prod-owl {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  body .content-up.col-sm-12 .slider-prod-owl, body .content-under.col-sm-12 .slider-prod-owl, body .content-fullup .slider-prod-owl {
    padding-left: 90px;
    padding-right: 90px;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  body .content-up.col-sm-12 .slider-prod-owl, body .content-under.col-sm-12 .slider-prod-owl, body .content-fullup .slider-prod-owl {
    padding-left: 50px;
    padding-right: 50px;
  }
}

body .showcase-all .wrap-panel {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

body .showcase-all .wrap-panel [class*="-layout"] {
  margin: 0;
  margin-top: 50px;
  flex: 0 0 292px;
}

body .showcase-all .nav-tabs {
  margin-bottom: 0;
}

body #column-right .showcase-all .wrap-panel [class*="-layout"], body #column-left .showcase-all .wrap-panel [class*="-layout"] {
  max-width: 100%;
}

body .showcase_auto_height .product-thumb .image {
  min-height: 140px;
  height: auto;
}

body .showcase_auto_height .product-thumb .image > a {
  line-height: 20px;
}

body .showcase_auto_height .information-sm {
  min-height: 140px;
}

body .showcase_auto_height .information-sm .descript {
  line-height: 17px;
  height: 70px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

body .showcase_auto_height .slider-prod-owl.owl-carousel .owl-buttons .owl-next, body .showcase_auto_height .slider-prod-owl.owl-carousel .owl-buttons .owl-prev {
  top: 30%;
}

body .showcase-left .nav-tabs {
  margin-bottom: 0;
  padding-right: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 260px;
}

@media (min-width: 768px) and (max-width: 1200px) {
  body .showcase-left .nav-tabs {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  body .showcase-left .nav-tabs {
    padding-right: 0;
    flex-direction: row;
    min-height: 30px;
    margin-bottom: 50px;
  }
}

body .showcase-left .nav-tabs > li {
  margin: 13px 0;
  display: block;
}

body .showcase-left .nav-tabs > li > a {
  display: inline-block;
}

@media (max-width: 767px) {
  body .showcase-left .nav-tabs > li {
    margin: 0;
  }
}

body .showcase-left:after {
  content: '';
  display: table;
  clear: both;
}

body .col-3 .showcase-left .nav-tabs, body .col-2 .showcase-left .nav-tabs, body #column-right .showcase-left .nav-tabs, body #column-left .showcase-left .nav-tabs {
  padding-right: 0;
}

body #column-right .showcase-left .nav-tabs, body #column-left .showcase-left .nav-tabs {
  width: 100%;
  padding-right: 0;
  flex-direction: row;
  min-height: 30px;
  margin-bottom: 50px;
}

body #column-right .showcase-left .nav-tabs > li, body #column-left .showcase-left .nav-tabs > li {
  margin: 0;
}

body #column-right .showcase-left .tab-content, body #column-left .showcase-left .tab-content {
  width: 100%;
}

@media (max-width: 1200px) {
  body .col-3 .showcase-left .nav-tabs {
    width: 100%;
    padding-right: 0;
    flex-direction: row;
    min-height: 30px;
    margin-bottom: 50px;
  }
  body .col-3 .showcase-left .nav-tabs > li {
    margin: 0;
  }
  body .col-3 .showcase-left .tab-content {
    width: 100%;
  }
}

body .showcase_nopadding .slider-prod-owl .owl-wrapper-outer {
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: content-box;
}

body .showcase_nopadding [class*="-layout"] {
  padding-left: 1px;
  padding-right: 1px;
  margin-bottom: 15px;
  position: relative;
}

body .showcase_nopadding [class*="-layout"] .product-thumb .caption {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
}

body .showcase_nopadding [class*="-layout"] .product-thumb .caption .head-product {
  min-height: 20px;
  margin-bottom: 10px;
}

body .showcase_nopadding [class*="-layout"] .product-thumb .caption .price {
  margin-bottom: 5px;
}

body .showcase_nopadding [class*="-layout"] .product-thumb .image {
  padding: 15px 15px 40px 15px;
  background-color: transparent;
}

body .showcase_nopadding [class*="-layout"] .product-thumb .image > a {
  line-height: 200px;
}

body .showcase_nopadding [class*="-layout"] .product-thumb .image img {
  max-height: 200px;
}

body .showcase_nopadding [class*="-layout"].product-layout .product-thumb .caption .head-product {
  display: none;
}

body .showcase_nopadding [class*="-layout"].product-layout .product-thumb .btn-absolute {
  bottom: 35px;
}

body .showcase_nopadding [class*="-layout"].information-layout .product-thumb .caption {
  position: relative;
}

body .showcase_nopadding [class*="-layout"].information-layout .product-thumb .caption .button-group {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2px 15px 15px 15px;
  text-align: left;
  background: transparent;
}

body .showcase_nopadding .owl-item:hover {
  z-index: 9999;
  position: relative;
}

@media (min-width: 992px) {
  body #content.col-sm-12 .content-top .showcase_nopadding .slider-prod-owl, body #content.col-sm-12 .content_bottom .showcase_nopadding .slider-prod-owl {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  body #content.col-sm-12 .content-top .showcase_nopadding .slider-prod-owl, body #content.col-sm-12 .content_bottom .showcase_nopadding .slider-prod-owl {
    padding-left: 70px;
    padding-right: 70px;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  body #content.col-sm-12 .content-top .showcase_nopadding .slider-prod-owl, body #content.col-sm-12 .content_bottom .showcase_nopadding .slider-prod-owl {
    padding-left: 35px;
    padding-right: 35px;
  }
}

body .slider-prod-owl {
  margin: 0 auto;
}

body #column-right .showcase_nopadding [class*="-layout"], body #column-left .showcase_nopadding [class*="-layout"], body .col-3 .showcase_nopadding [class*="-layout"] {
  padding-left: 15px;
  padding-right: 15px;
}

body .showcase_all_nopadding .wrap-panel {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

body .showcase_all_nopadding .wrap-panel [class*="-layout"] {
  padding-left: 1px;
  padding-right: 1px;
  margin: 1px 0;
  flex: 0 0 262px;
}

body .showcase_all_nopadding .wrap-panel [class*="-layout"] .product-thumb {
  position: relative;
}

body .showcase_all_nopadding .wrap-panel [class*="-layout"] .product-thumb .caption {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 0;
}

body .showcase_all_nopadding .wrap-panel [class*="-layout"] .product-thumb .caption .head-product {
  min-height: 20px;
  margin-bottom: 10px;
}

body .showcase_all_nopadding .wrap-panel [class*="-layout"] .product-thumb .caption .price {
  margin-bottom: 5px;
}

body .showcase_all_nopadding .wrap-panel [class*="-layout"] .product-thumb .image {
  background-color: transparent;
}

body .showcase_all_nopadding .wrap-panel [class*="-layout"].product-layout .product-thumb .caption .head-product {
  display: none;
}

body .showcase_all_nopadding .wrap-panel [class*="-layout"].product-layout .product-thumb .btn-absolute {
  bottom: 35px;
}

body .showcase_all_nopadding .wrap-panel [class*="-layout"].information-layout .product-thumb .caption {
  position: relative;
}

body .showcase_all_nopadding .wrap-panel [class*="-layout"].information-layout .product-thumb .caption .button-group {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2px 15px 15px 15px;
  text-align: left;
  background-color: transparent;
}

body .showcase_all_nopadding .wrap-panel [class*="-layout"].information-layout .product-thumb .flex-descript {
  margin-bottom: 0;
}

body .showcase_all_nopadding .wrap-panel [class*="-layout"]:hover {
  z-index: 9999;
  position: relative;
}

body #column-right .showcase_all_nopadding .wrap-panel [class*="-layout"], body #column-left .showcase_all_nopadding .wrap-panel [class*="-layout"] {
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

body .showcase_gorisontal_view [class*="-layout"], body .showcase_gorisontal_view_all [class*="-layout"] {
  max-width: 390px;
}

body .showcase_gorisontal_view [class*="-layout"] .product-thumb, body .showcase_gorisontal_view_all [class*="-layout"] .product-thumb {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

body .showcase_gorisontal_view [class*="-layout"] .product-thumb .image, body .showcase_gorisontal_view_all [class*="-layout"] .product-thumb .image {
  height: 165px;
}

body .showcase_gorisontal_view [class*="-layout"] .product-thumb .image > a, body .showcase_gorisontal_view_all [class*="-layout"] .product-thumb .image > a {
  line-height: 140px;
}

body .showcase_gorisontal_view [class*="-layout"]:not(.information-layout) .caption, body .showcase_gorisontal_view_all [class*="-layout"]:not(.information-layout) .caption {
  text-align: left;
  padding-left: 30px;
}

body .showcase_gorisontal_view [class*="-layout"]:not(.information-layout) .price, body .showcase_gorisontal_view_all [class*="-layout"]:not(.information-layout) .price {
  text-align: left;
}

body .showcase_gorisontal_view [class*="-layout"]:not(.information-layout) .image, body .showcase_gorisontal_view_all [class*="-layout"]:not(.information-layout) .image {
  flex: 0 0 165px;
}

body .showcase_gorisontal_view [class*="-layout"].information-layout .product-thumb .caption, body .showcase_gorisontal_view_all [class*="-layout"].information-layout .product-thumb .caption {
  position: relative;
  width: 100%;
}

body .showcase_gorisontal_view [class*="-layout"].information-layout .product-thumb .caption .button-group, body .showcase_gorisontal_view_all [class*="-layout"].information-layout .product-thumb .caption .button-group {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 4px 2px 15px 2px;
  text-align: left;
  padding-left: 15px;
}

body .showcase_gorisontal_view [class*="-layout"].information-layout .product-thumb .caption .descript, body .showcase_gorisontal_view_all [class*="-layout"].information-layout .product-thumb .caption .descript {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

body .showcase_gorisontal_view .slider-prod-owl.owl-carousel .owl-buttons .owl-next, body .showcase_gorisontal_view .slider-prod-owl.owl-carousel .owl-buttons .owl-prev, body .showcase_gorisontal_view_all .slider-prod-owl.owl-carousel .owl-buttons .owl-next, body .showcase_gorisontal_view_all .slider-prod-owl.owl-carousel .owl-buttons .owl-prev {
  top: 80px;
}

@media (max-width: 1200px) {
  body #column-right .showcase_gorisontal_view [class*="-layout"] .product-thumb, body #column-right .showcase_gorisontal_view_all [class*="-layout"] .product-thumb, body #column-left .showcase_gorisontal_view [class*="-layout"] .product-thumb, body #column-left .showcase_gorisontal_view_all [class*="-layout"] .product-thumb {
    flex-direction: row;
    flex-wrap: wrap;
  }
  body #column-right .showcase_gorisontal_view [class*="-layout"] .product-thumb .image, body #column-right .showcase_gorisontal_view_all [class*="-layout"] .product-thumb .image, body #column-left .showcase_gorisontal_view [class*="-layout"] .product-thumb .image, body #column-left .showcase_gorisontal_view_all [class*="-layout"] .product-thumb .image {
    width: 100%;
  }
  body #column-right .showcase_gorisontal_view [class*="-layout"]:not(.information-layout) .caption, body #column-right .showcase_gorisontal_view_all [class*="-layout"]:not(.information-layout) .caption, body #column-left .showcase_gorisontal_view [class*="-layout"]:not(.information-layout) .caption, body #column-left .showcase_gorisontal_view_all [class*="-layout"]:not(.information-layout) .caption {
    width: 100%;
  }
  body #column-right .showcase_gorisontal_view [class*="-layout"]:not(.information-layout) .image, body #column-right .showcase_gorisontal_view_all [class*="-layout"]:not(.information-layout) .image, body #column-left .showcase_gorisontal_view [class*="-layout"]:not(.information-layout) .image, body #column-left .showcase_gorisontal_view_all [class*="-layout"]:not(.information-layout) .image {
    flex: 1 0 100%;
  }
}

body .showcase_gorisontal_view_all .wrap-panel {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

body .showcase_gorisontal_view_all .wrap-panel [class*="-layout"] {
  flex: 1 0 360px;
  margin: 0;
}

@media (max-width: 400px) {
  body .showcase_gorisontal_view_all .wrap-panel [class*="-layout"] {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  body .showcase_gorisontal_view_all .wrap-panel .flex-descript {
    margin-bottom: 0;
  }
}

body #column-right .showcase_gorisontal_view_all .wrap-panel [class*="-layout"], body #column-left .showcase_gorisontal_view_all .wrap-panel [class*="-layout"] {
  max-width: 100%;
}

body .socials .at-share-tbx-element .at-share-btn {
  transform: none;
}

body .socials a {
  margin: 10px 5px;
  display: inline-block;
}

body .socials a:hover {
  text-decoration: none;
}

body .socials .fa {
  font-size: 22px;
}

body .content-up .advanced-banners, body .content-under .advanced-banners, body .content-top .advanced-banners, body .content-bottom .advanced-banners {
  margin: 0 -15px;
}

body .content-fullunder .advanced-banners .advanced-container, body .content-fullup .advanced-banners .advanced-container {
  padding-left: 15px;
  padding-right: 15px;
}

body .content-fullunder .advanced-banners {
  padding: 55px 15px 40px 15px;
}

body .advanced-banners {
  padding-left: 15px;
  padding-right: 15px;
}

body .advanced-banners .advanced-container {
  margin: 0 auto;
}

@media (min-width: 320px) {
  body .advanced-banners .advanced-container {
    max-width: 420px;
  }
}

@media (min-width: 768px) {
  body .advanced-banners .advanced-container {
    max-width: 750px;
  }
}

@media (min-width: 992px) {
  body .advanced-banners .advanced-container {
    max-width: 970px;
  }
}

@media (min-width: 1200px) {
  body .advanced-banners .advanced-container {
    max-width: 1170px;
  }
}

body .advanced-banners .heading-module {
  padding-bottom: 30px;
}

body .advanced-banners .heading-module span {
  background-color: transparent;
}

body .advanced-banners .advanced-column {
  float: left;
  height: 341px;
  padding: 15px;
}

body .advanced-banners .advanced-column .container-img {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  overflow: hidden;
}

body .advanced-banners .advanced-column .container-img .text {
  position: absolute;
  z-index: 99;
  text-align: center;
  display: inline-block;
  padding: 40px 35px;
  width: 100%;
  font-size: 22px;
  font-weight: 300;
  line-height: 22px;
}

body .advanced-banners .advanced-column .container-img .text.text_top {
  top: 0;
}

body .advanced-banners .advanced-column .container-img .text.text_bottom {
  top: auto;
  bottom: 0;
}

body .advanced-banners .advanced-column img {
  max-width: 100%;
  position: absolute;
  width: auto;
  height: auto;
  right: 0;
  left: 0;
  margin: 0 auto;
}

body .advanced-banners .advanced-column:not(.resize-img) img {
  max-height: 100%;
}

body .advanced-banners .advanced-column:not(.resize-img) .container-img {
  background-image: none !important;
}

body .advanced-banners .advanced-column.col-first {
  width: 41.66666667%;
}

body .advanced-banners .advanced-column.col-second {
  width: 58.33333333%;
  float: right;
  height: 682px;
}

body .advanced-banners .advanced-column.col-third {
  width: 41.66666667%;
}

body .advanced-banners .advanced-carusel {
  height: 100%;
  transition: .3s;
}

body .advanced-banners .advanced-carusel .owl-wrapper-outer, body .advanced-banners .advanced-carusel .owl-wrapper, body .advanced-banners .advanced-carusel .owl-item {
  height: 100%;
}

body .advanced-banners .resize-img.advanced-column img {
  min-width: 100%;
  min-height: 100%;
  max-width: 110%;
  max-height: 110%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  left: 50%;
  display: none;
}

body .advanced-banners .resize-img.advanced-column .container-img {
  background-size: cover;
  background-position: center;
}

body .advanced-banners.advanced-right .advanced-column {
  float: right;
}

body .advanced-banners.advanced-right .advanced-column.col-second {
  float: left;
}

body .advanced-banners.advanced-square .advanced-column {
  width: 50%;
  height: 585px;
}

body .advanced-banners.advanced-square .advanced-column.col-second {
  width: 50%;
  height: 585px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  body .advanced-banners.advanced-square .advanced-column {
    height: 485px;
  }
  body .advanced-banners.advanced-square .advanced-column.col-second {
    height: 485px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  body .advanced-banners.advanced-square .advanced-column {
    height: 375px;
  }
  body .advanced-banners.advanced-square .advanced-column.col-second {
    height: 375px;
  }
}

@media (max-width: 767px) {
  body .advanced-banners.advanced-square .advanced-column {
    height: 320px;
  }
  body .advanced-banners.advanced-square .advanced-column.col-first {
    width: 100%;
  }
  body .advanced-banners.advanced-square .advanced-column.col-second {
    height: 320px;
    width: 100%;
  }
}

body .advanced-banners.advanced-rect .advanced-column {
  width: 50%;
  height: calc(3/4*585px);
}

body .advanced-banners.advanced-rect .advanced-column.col-second {
  width: 50%;
  height: calc(3/4*585px);
}

@media (min-width: 992px) and (max-width: 1200px) {
  body .advanced-banners.advanced-rect .advanced-column {
    height: calc(3/4*485px);
  }
  body .advanced-banners.advanced-rect .advanced-column.col-second {
    height: calc(3/4*485px);
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  body .advanced-banners.advanced-rect .advanced-column {
    height: calc(3/4*375px);
  }
  body .advanced-banners.advanced-rect .advanced-column.col-second {
    height: calc(3/4*375px);
  }
}

@media (max-width: 767px) {
  body .advanced-banners.advanced-rect .advanced-column {
    height: calc(3/4*320px);
  }
  body .advanced-banners.advanced-rect .advanced-column.col-first {
    width: 100%;
  }
  body .advanced-banners.advanced-rect .advanced-column.col-second {
    height: calc(3/4*320px);
    width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  body .advanced-banners .advanced-column {
    height: 283px;
  }
  body .advanced-banners .advanced-column.col-second {
    height: 566px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  body .advanced-banners .advanced-column {
    height: 218px;
  }
  body .advanced-banners .advanced-column.col-second {
    height: 437px;
  }
}

@media (max-width: 767px) {
  body .advanced-banners .advanced-column {
    height: 300px;
  }
  body .advanced-banners .advanced-column.col-first {
    width: 100%;
  }
  body .advanced-banners .advanced-column.col-second {
    height: 450px;
    width: 100%;
  }
  body .advanced-banners .advanced-column.col-third {
    width: 100%;
  }
}

body .col-3 .advanced-column {
  height: 218px;
}

body .col-3 .advanced-column.col-first {
  width: 100%;
}

body .col-3 .advanced-column.col-second {
  height: 437px;
  width: 100%;
}

body .col-3 .advanced-column.col-third {
  width: 100%;
}

body #column-left .advanced-column, body #column-right .advanced-column {
  height: 218px;
}

body #column-left .advanced-column.col-first, body #column-right .advanced-column.col-first {
  width: 100%;
}

body #column-left .advanced-column.col-second, body #column-right .advanced-column.col-second {
  height: 280px;
  width: 100%;
}

body #column-left .advanced-column.col-third, body #column-right .advanced-column.col-third {
  width: 100%;
}

body #column-left .advanced-column .container-img .text, body #column-right .advanced-column .container-img .text {
  font-size: 16px;
  padding: 15px;
}

body .advanced-banners:after {
  content: '';
  display: block;
  clear: both;
}

body .product-labels {
  position: absolute;
  list-style-type: none;
  right: 0;
  top: 0;
  text-align: right;
  padding: 15px;
  line-height: 23px;
}

body .product-labels > li {
  display: block;
}

body .product-labels > li > span {
  border-radius: 3px;
  margin-bottom: 0;
  padding: 4px 5px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  height: 26px;
  display: -webkit-inline-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  line-height: 20px;
}

body .product-labels.product-page-labels {
  right: 15px;
  left: 15px;
}

body .megaMenu .navbar-nav.menu_stage_1 > li.has-child:hover > .sub_menu {
  padding-top: 20px;
}

@media (max-width: 991px) and (min-width: 767px) {
  body .megaMenu .navbar-nav.menu_stage_1 > li.has-child:hover > .sub_menu {
    margin-top: 0;
    padding-top: 0;
  }
}

@media (max-width: 767px) {
  body .megaMenu .navbar-nav.menu_stage_1 > li.has-child:hover > .sub_menu {
    padding-top: 0;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  body .megaMenu .navbar-nav.menu_stage_1 > li.has-child > .caret-container {
    position: relative;
    display: inline-block;
  }
  body .megaMenu .navbar-nav.menu_stage_1 > li.has-child > .caret-container:after {
    content: '|';
    position: absolute;
    right: -1px;
  }
  body .megaMenu .navbar-nav.menu_stage_1 > li.has-child > a {
    padding-right: 0;
    display: inline-block;
  }
  body .megaMenu .navbar-nav.menu_stage_1 > li.has-child > a:after {
    display: none;
  }
}

@media (max-width: 767px) {
  body .megaMenu .navbar-nav.menu_stage_1 > li.has-child > .mob-dropdown {
    position: absolute !important;
    text-decoration: none;
    margin: 0;
  }
}

@media (min-width: 992px) {
  body .megaMenu .navbar-nav > li > a, body .megaMenu .navbar-nav > li > span {
    display: inline-block;
  }
}

body .megaMenu .navbar-nav > li > a:after, body .megaMenu .navbar-nav > li > span:after {
  right: -1px;
  top: 3px;
}

@media (max-width: 767px) {
  body .megaMenu .navbar-nav > li > a, body .megaMenu .navbar-nav > li > span {
    margin-right: 0 !important;
  }
}

@media (max-width: 991px) and (min-width: 767px) {
  body .megaMenu .navbar-nav > li > span {
    padding-right: 0;
  }
  body .megaMenu .navbar-nav > li > span:after {
    display: none;
  }
}

body .megaMenu .navbar-nav > li span:hover {
  cursor: default;
}

@media (max-width: 767px) {
  body .megaMenu .navbar-nav > li span {
    color: #fff;
    display: block;
  }
}

body .megaMenu .navbar-nav > li:last-child > a:after, body .megaMenu .navbar-nav > li:last-child > span:after {
  display: none;
}

body .megaMenu .navbar-nav > li.has-child {
  position: static;
}

@media (max-width: 767px) {
  body .megaMenu .navbar-nav > li.has-child {
    position: relative;
  }
}

body .megaMenu .navbar-nav > li.has-child .sub_menu {
  left: 0;
  right: 0;
  width: auto;
  min-width: 100px;
  max-width: 100%;
  margin-left: 0;
  margin-bottom: 20px;
  padding-top: 0;
  margin-top: 20px;
}

@media (max-width: 991px) and (min-width: 767px) {
  body .megaMenu .navbar-nav > li.has-child .sub_menu {
    top: 100%;
    margin-top: 0;
  }
}

body .megaMenu .navbar-nav > li.has-child .sub_menu .sub_menu_contein {
  padding: 15px;
  box-sizing: content-box;
}

body .megaMenu .navbar-nav > li.has-child .sub_menu .sub_menu_contein:after {
  display: block;
}

@media (max-width: 767px) {
  body .megaMenu .navbar-nav > li.has-child .sub_menu .sub_menu_contein {
    padding: 0 15px;
  }
}

@media (max-width: 991px) and (min-width: 767px) {
  body .megaMenu .navbar-nav > li.has-child .sub_menu {
    box-shadow: none;
  }
}

body .megaMenu .navbar-nav > li.has-child .sub_menu .sub_menu {
  position: relative;
}

body .megaMenu .navbar-nav > li.has-child .sub_menu .sub_menu .sub_menu_contein {
  box-shadow: none;
  background: transparent;
  padding: 0;
}

body .megaMenu .navbar-nav > li.has-child .sub_menu a, body .megaMenu .navbar-nav > li.has-child .sub_menu span {
  display: inline-block;
  word-break: break-word;
}

body .megaMenu .navbar-nav > li.has-child .sub_menu a.all_href_text, body .megaMenu .navbar-nav > li.has-child .sub_menu span.all_href_text {
  margin: 10px 15px;
  text-decoration: underline;
}

body .megaMenu .navbar-nav > li.has-child .sub_menu a.all_href_text:hover, body .megaMenu .navbar-nav > li.has-child .sub_menu span.all_href_text:hover {
  text-decoration: none;
}

body .megaMenu .navbar-nav > li.has-child .sub_menu .menu_stage_2:after {
  content: '';
  display: table;
  clear: both;
}

@media (max-width: 767px) {
  body .megaMenu .navbar-nav > li.has-child .sub_menu .menu_stage_2 {
    margin-left: 20px;
  }
}

body .megaMenu .navbar-nav > li.has-child .sub_menu .menu_stage_2 > li {
  float: left;
  width: 25%;
  display: inline-block;
  padding: 15px;
}

body .megaMenu .navbar-nav > li.has-child .sub_menu .menu_stage_2 > li:nth-child(4n+5) {
  clear: left;
}

@media (min-width: 992px) and (max-width: 1200px) {
  body .megaMenu .navbar-nav > li.has-child .sub_menu .menu_stage_2 > li {
    width: 33.3%;
  }
  body .megaMenu .navbar-nav > li.has-child .sub_menu .menu_stage_2 > li:nth-child(4n+5) {
    clear: none;
  }
  body .megaMenu .navbar-nav > li.has-child .sub_menu .menu_stage_2 > li:nth-child(3n+4) {
    clear: left;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  body .megaMenu .navbar-nav > li.has-child .sub_menu .menu_stage_2 > li {
    width: 50%;
  }
  body .megaMenu .navbar-nav > li.has-child .sub_menu .menu_stage_2 > li:nth-child(4n+5) {
    clear: none;
  }
  body .megaMenu .navbar-nav > li.has-child .sub_menu .menu_stage_2 > li:nth-child(3n+4) {
    clear: none;
  }
  body .megaMenu .navbar-nav > li.has-child .sub_menu .menu_stage_2 > li:nth-child(2n+3) {
    clear: left;
  }
}

@media (max-width: 767px) {
  body .megaMenu .navbar-nav > li.has-child .sub_menu .menu_stage_2 > li {
    width: 100% !important;
    padding: 8px 15px;
    float: none;
  }
}

body .megaMenu .navbar-nav > li.has-child .sub_menu .menu_stage_2 > li > a, body .megaMenu .navbar-nav > li.has-child .sub_menu .menu_stage_2 > li > span {
  text-transform: uppercase;
  display: block;
}

@media (max-width: 767px) {
  body .megaMenu .navbar-nav > li.has-child .sub_menu .menu_stage_2 > li > a, body .megaMenu .navbar-nav > li.has-child .sub_menu .menu_stage_2 > li > span {
    display: inline-block;
  }
}

body .megaMenu .navbar-nav > li.has-child .sub_menu .menu_stage_2 > li > img {
  padding-right: 0;
  margin-bottom: 20px;
  max-width: 100%;
}

@media (max-width: 767px) {
  body .megaMenu .navbar-nav > li.has-child .sub_menu .menu_stage_2 > li > img {
    padding-right: 10px;
    margin-bottom: 0;
    max-width: 35px;
  }
}

@media (max-width: 767px) {
  body .megaMenu .navbar-nav > li.has-child .sub_menu .menu_stage_3, body .megaMenu .navbar-nav > li.has-child .sub_menu .menu_stage_4, body .megaMenu .navbar-nav > li.has-child .sub_menu .menu_stage_5 {
    margin-left: 10px;
  }
}

body .megaMenu .navbar-nav > li.has-child .sub_menu .menu_stage_3 > li.has-child > .sub_menu, body .megaMenu .navbar-nav > li.has-child .sub_menu .menu_stage_4 > li.has-child > .sub_menu, body .megaMenu .navbar-nav > li.has-child .sub_menu .menu_stage_5 > li.has-child > .sub_menu {
  margin-left: 25px;
}

@media (max-width: 767px) {
  body .megaMenu .navbar-nav > li.has-child .sub_menu .menu_stage_3 > li.has-child > .sub_menu, body .megaMenu .navbar-nav > li.has-child .sub_menu .menu_stage_4 > li.has-child > .sub_menu, body .megaMenu .navbar-nav > li.has-child .sub_menu .menu_stage_5 > li.has-child > .sub_menu {
    margin-left: 0;
  }
}

body .megaMenu .navbar-nav > li.has-child:hover > .sub_menu {
  padding-top: 20px;
  margin-top: 0;
}

body .megaMenu .navbar-nav > li.has-child:hover .sub_menu {
  padding-top: 20px;
  margin-top: 0;
}

@media (max-width: 991px) and (min-width: 767px) {
  body .megaMenu .navbar-nav > li.has-child:hover .sub_menu {
    margin-top: 20px;
  }
}

body .megaMenu .navbar-nav > li.has-child > .sub_menu {
  margin-left: 0;
}

body .megaMenu .navbar-nav > li.has-child .sub_menu .menu_stage_2 > li > .sub_menu {
  margin-left: 0;
}

@media (max-width: 991px) and (min-width: 768px) {
  body .megaMenu .navbar-nav > li.has-child .caret-container {
    display: none;
  }
}

body .megaMenu .navbar-nav > li.has-image .sub_menu .menu_stage_2 > li {
  width: 33.3%;
}

body .megaMenu .navbar-nav > li.has-image .sub_menu .menu_stage_2 > li:nth-child(4n+5) {
  clear: none;
}

body .megaMenu .navbar-nav > li.has-image .sub_menu .menu_stage_2 > li:nth-child(3n+4) {
  clear: left;
}

@media (min-width: 992px) and (max-width: 1200px) {
  body .megaMenu .navbar-nav > li.has-image .sub_menu .menu_stage_2 > li {
    width: 50%;
  }
  body .megaMenu .navbar-nav > li.has-image .sub_menu .menu_stage_2 > li:nth-child(3n+4) {
    clear: none;
  }
  body .megaMenu .navbar-nav > li.has-image .sub_menu .menu_stage_2 > li:nth-child(2n+3) {
    clear: left;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  body .megaMenu .navbar-nav > li.has-image .sub_menu .menu_stage_2 > li {
    width: 100%;
  }
}

@media (max-width: 767px) {
  body .megaMenu .navbar-nav li.has-child {
    position: relative;
    padding-right: 0;
  }
  body .megaMenu .navbar-nav li.has-child.has-image .sub_menu a, body .megaMenu .navbar-nav li.has-child .has-image a {
    padding-left: 25px;
  }
}

body .megaMenu .navbar-nav li.has-image img, body .megaMenu .navbar-nav li.has-image a {
  display: inline-block;
}

body .megaMenu .navbar-nav li.has-image img {
  padding-right: 10px;
  max-width: 32px;
}

@media (max-width: 767px) {
  body .megaMenu .navbar-nav li.has-image {
    position: relative;
  }
  body .megaMenu .navbar-nav li.has-image img {
    position: absolute;
    left: 0;
    top: 4px;
  }
  body .megaMenu .navbar-nav li.has-image > a {
    padding-left: 25px;
  }
}

body .megaMenu .navbar-nav.menu_stage_1 > li.has-image > img {
  position: absolute;
  top: 80px;
  right: 15px;
  max-width: 300px;
  z-index: 999;
  opacity: 0;
  height: 0;
  transition: opacity 1s;
  padding-right: 0;
  margin-right: 0 !important;
}

@media (max-width: 991px) and (min-width: 768px) {
  body .megaMenu .navbar-nav.menu_stage_1 > li.has-image > img {
    padding-top: 15px;
    top: 100%;
  }
}

@media (max-width: 767px) {
  body .megaMenu .navbar-nav.menu_stage_1 > li.has-image > a, body .megaMenu .navbar-nav.menu_stage_1 > li.has-image > img + span {
    padding-left: 55px;
    padding-right: 40px;
  }
  body .megaMenu .navbar-nav.menu_stage_1 > li.has-image > img {
    position: absolute;
    opacity: 1;
    height: auto;
    top: 1px !important;
    max-width: 50px !important;
    width: auto;
    max-height: 45px;
    padding-left: 15px;
    left: 0;
  }
  body .megaMenu .navbar-nav.menu_stage_1 > li.has-image.active_mob > img {
    position: absolute;
    opacity: 1;
    height: auto;
    top: 1px !important;
    max-width: 50px !important;
    width: auto;
    max-height: 45px;
    padding-left: 15px;
    left: 0;
  }
  body .megaMenu .navbar-nav.menu_stage_1 > li.has-image.active_mob:hover > img {
    opacity: 1;
    display: block;
  }
}

body .megaMenu .navbar-nav.menu_stage_1 > li.has-image > .sub_menu > .sub_menu_contein {
  padding-right: 300px;
}

@media (max-width: 767px) {
  body .megaMenu .navbar-nav.menu_stage_1 > li.has-image > .sub_menu > .sub_menu_contein {
    padding-right: 15px;
    min-height: 10px !important;
  }
}

body .megaMenu .navbar-nav.menu_stage_1 > li.has-image:hover > img {
  opacity: 1;
  height: auto;
}

@media (max-width: 991px) and (min-width: 768px) {
  body .megaMenu .navbar-nav.menu_stage_1 > li.has-image:hover > img {
    opacity: 0;
    height: 0;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  body .megaMenu .navbar-nav.menu_stage_1 > li.has-image.active_sm > img, body .megaMenu .navbar-nav.menu_stage_1 > li.has-image.active_sm:hover > img {
    opacity: 1;
    height: auto;
  }
}

@media (max-width: 991px) {
  body .megaMenu > .mobile .navbar-nav > li.has-child img, body .megaMenu > .mobile .navbar-nav > li img {
    display: none !important;
  }
  body .megaMenu > .mobile .navbar-nav > li.has-child > .sub_menu > .sub_menu_contein, body .megaMenu > .mobile .navbar-nav > li > .sub_menu > .sub_menu_contein {
    padding-right: 15px;
  }
  body .megaMenu > .mobile .navbar-nav > li.has-child > .sub_menu .menu_stage_2 > li, body .megaMenu > .mobile .navbar-nav > li > .sub_menu .menu_stage_2 > li {
    width: 50%;
  }
}

@media (max-width: 767px) {
  body .megaMenu > .mobile .navbar-nav.menu_stage_1 > li.has-image > a, body .megaMenu > .mobile .navbar-nav.menu_stage_1 > li.has-image > img + span {
    padding-left: 15px;
  }
}

body .tooltip {
  z-index: 99999;
}
